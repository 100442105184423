var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"event-date-filters"},[_c('div',{staticClass:"container container--thin"},[_c('div',{staticClass:"h5 mb-2"},[_vm._v("Showing events on these date(s):")]),_vm._v(" "),_c('div',{staticClass:"date-select",class:[
        { 'is-open': _vm.datePickerOpened },
        {
          'date-select--right': _vm.align === 'right',
        } ],attrs:{"role":"button","tabindex":"-1"}},[(!_vm.$isMobile(_vm.$mq))?_c('DatePicker',{attrs:{"preset-range-selection":_vm.currentRange,"display-format":"DD MMM YY"},on:{"opened":_vm.onDatePickerOpened,"closed":_vm.onDatePickerClosed,"applyDateRange":_vm.onDateRangeApplied},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
      var isOpen = ref.isOpen;
      var toggleIsOpen = ref.toggleIsOpen;
      var showAppliedSelection = ref.showAppliedSelection;
return [_c('div',{staticClass:"date-select__trigger button button--tertiary",on:{"click":function($event){$event.preventDefault();return toggleIsOpen.apply(null, arguments)}}},[_c('span',{staticClass:"date-select__trigger-selection",domProps:{"innerHTML":_vm._s(showAppliedSelection)}}),_vm._v(" "),_c('CalendarSvg')],1),_vm._v(" "),_c('button',{staticClass:"date-select__close",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return toggleIsOpen.apply(null, arguments)}}},[(isOpen)?_c('CloseSvg'):_vm._e(),_c('span',{staticClass:"sr-only"},[_vm._v("Close datepicker")])],1)]}}],null,false,1661429513)}):_c('div',{staticClass:"date-select__trigger date-select__trigger--mobile"},[_c('MobileRangeFields',{on:{"date-range-applied":_vm.onMobileDateApplied},model:{value:(_vm.dateRangeSelection),callback:function ($$v) {_vm.dateRangeSelection=$$v},expression:"dateRangeSelection"}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
import cloneDeep from 'lodash/cloneDeep'
import { Screen, ScreenZone, PriceType, Price } from '@Core/@types/skyway'

type PriceRange = {
  min: number
  max: number
}

export type ScreenType = {
  priceFrom: number
  priceRange: PriceRange
  availability: number
  configuration: string
}

/**
 * Array of price names that should not be included
 * in min pricing calculations
 * we can't avoid string matching on these
 */
export const pricesToExclude = [
  '10 and under',
  '12 and under',
  '15 - 25s',
  '15 & under',
  '15 and under',
  '16-25 year olds',
  '25 and under',
  '60 and over',
  'access',
  'ages 4-7',
  'ages 8-12',
  'babe in arms',
  'child',
  'concession',
  'family of 2',
  'family of 3',
  'family of 4',
  'family of 5',
  'froup',
  'froup 6+',
  'froup 8+',
  'froup 10+',
  'school group',
  'senior',
  'student',
  'studios members',
  'teacher',
  'under 35s',
  'undwaged',
  'wheelchair & pa 15 and under',
]

/**
 * Disregard screenZones that do not have valid priceTypes
 */
export const filterScreensByPrices = (
  priceTypes: PriceType[],
  screenZones: ScreenZone[]
): ScreenZone[] => {
  const selectedPriceTypeRefs = priceTypes.flatMap((pt) => pt.price_type_ref)

  return screenZones.filter((sz) => {
    const validPriceTypeRefs =
      sz.zones
        ?.flatMap((z) => z?.valid_prices?.map((vp) => vp?.price_type_ref))
        .filter((ptRef) => selectedPriceTypeRefs.includes(ptRef)) || []

    return validPriceTypeRefs?.length > 0
  })
}

export const isSubstringInArray = (substring: string, array: string[]) => {
  for (const str of array) {
    if (str.includes(substring)) {
      return true
    }
  }
  return false
}

/**
 * Given a screenZone, calculate the min and max prices
 * by looping through the zones
 *
 * @param { ScreenZone } screenZone - A ScreenZone object
 * @param { boolean } availableOnly - Whether to exclude unavailable priceZones
 *
 * @return { min: number, max: number } - The min max prices
 */
export const calculateScreenZonePriceRange = (
  screenZone: ScreenZone,
  availableOnly: boolean
): { min: number; max: number } => {
  const { price_range, zones } = cloneDeep(screenZone)
  let min = price_range.min
  let max = price_range.max

  for (const zoneKey in zones) {
    const zone = zones[zoneKey]

    if (availableOnly && zone.availability <= 0) {
      continue // Skip unavailable zones
    }

    for (const price of zone.prices) {
      const priceTitle = price.price_title.toLowerCase()

      if (isSubstringInArray(priceTitle, pricesToExclude)) {
        continue // Skip excluded prices
      }

      if (price.enabled) {
        max = Math.max(max, price.price)

        if (price.price >= 10) {
          min = Math.min(min, price.price)
        }
      }
    }
  }

  return { min, max }
}

/**
 * Screen type does not exist as a Tessitura entity.
 * We are merging the data from ScreenZones[] and
 * the Screen configuration to group Zones into
 * seated and standing types.
 *
 * @param { ScreenZone[] } screenZones - Array of ScreenZones
 * @param { string } configuration - Standing or seated (default: 'seated')
 * @param { PriceType[] } priceTypes - Array of PriceTypes
 *
 * @return { ScreenType } - Seated or Standing type
 */
export const screenType = (
  screenZones: ScreenZone[],
  configuration: string = 'seated',
  priceTypes: PriceType[] = []
): ScreenType => {
  // Filter screens by price types
  const filteredScreens = filterScreensByPrices(priceTypes, screenZones)

  // Filter available screens and calculate min price
  const availableScreens = filteredScreens.filter(
    (screen) => screen && Number(screen.availability) > 0
  )
  const minPrice = Math.min(
    ...availableScreens.map(
      (screen) => calculateScreenZonePriceRange(screen, true).min
    )
  )

  const maxPrice = Math.max(
    ...availableScreens.map(
      (screen) => calculateScreenZonePriceRange(screen, true).max
    )
  )

  // Calculate total availability
  const totalAvailability = availableScreens.reduce(
    (total, screen) => total + Number(screen.availability),
    0
  )

  return {
    priceFrom: minPrice,
    priceRange: { min: minPrice, max: maxPrice },
    availability: totalAvailability,
    configuration,
  }
}

export const mapScreenZoneToConfig = (
  screenZones: ScreenZone[],
  screens: Screen[]
) => {
  return screenZones.map((sz) => {
    const screen = screens.find((sc) => sc.screen_ref === sz.screen_ref)

    return {
      ...sz,
      title: screen?.title || sz.screen_title,
      seating_configuration:
        (screen && screen.seating_configuration) || 'seated',
    }
  })
}

























































import { Component, Prop, VModel, Vue } from 'nuxt-property-decorator'
import _debounce from 'lodash/debounce'
import DatePicker from '@UI/components/date-picker/DatePicker.vue'
import { DateRange } from '@UI/components/date-picker/defaultPresets'
import MobileRangeFields from '@UI/components/date-picker/MobileRangeFields.vue'
// @ts-ignore-next:line
import CalendarSvg from '~/static/images/svgs/calendar.svg?inline'
// @ts-ignore:next-line
import CloseSvg from '~/static/images/sprites/close.svg?inline'

@Component({
  components: {
    DatePicker,
    CalendarSvg,
    CloseSvg,
    MobileRangeFields,
  },
})
export default class EventDateFilter extends Vue {
  @Prop({ type: Object }) currentRange?: DateRange
  @Prop({ type: String, default: () => 'right' }) align?: string
  @VModel() dateRangeSelection: any

  protected datePickerOpened: boolean = false
  protected isStuck: boolean = false
  protected debouncedOnDateRangeApplied: Function = _debounce(
    this.onDateRangeApplied,
    500
  )

  protected dateFrom: HTMLInputElement | null = null
  protected dateTo: HTMLInputElement | null = null
  protected dateErrors: {
    from: string | null
    to: string | null
  } = {
    from: null,
    to: null,
  }

  get atStart(): boolean {
    return this.currentRange
      ? this.$moment(new Date()).isSameOrAfter(
          this.$moment(this.currentRange.from)
        )
      : false
  }

  onDatePickerOpened(): void {
    this.datePickerOpened = true
  }

  onDatePickerClosed(): void {
    this.datePickerOpened = false
  }

  onShowNextWeek(): void {
    this.onDateRangeApplied({
      from: this.$moment(this.currentRange.from)
        .add(1, 'week')
        .format('YYYY-MM-DD'),
      to: this.$moment(this.currentRange.to)
        .add(1, 'weeks')
        .format('YYYY-MM-DD'),
    })
  }

  onShowLastWeek(): void {
    this.onDateRangeApplied({
      from: this.$moment(this.currentRange.from)
        .subtract(1, 'weeks')
        .format('YYYY-MM-DD'),
      to: this.$moment(this.currentRange.to)
        .subtract(1, 'week')
        .format('YYYY-MM-DD'),
    })
  }

  onDateRangeApplied(payload: DateRange) {
    if (payload.from && payload.to) {
      const formatted = {
        from: this.$moment.utc(payload.from).format('YYYY-MM-DD'),
        to: this.$moment.utc(payload.to).format('YYYY-MM-DD'),
      }
      this.$emit('change-week', formatted)
    }
  }

  onMobileDateApplied(payload: DateRange) {
    this.debouncedOnDateRangeApplied(payload)
  }

  async mounted() {
    // await this.$nextTick()
    // this.dateFrom = this.$refs.date_from
    // this.dateTo = this.$refs.date_to
  }
}





























































import {
  Component,
  Vue,
  Prop,
  Watch,
  Emit,
  InjectReactive,
  Inject,
} from 'nuxt-property-decorator'
import _debounce from 'lodash.debounce'
import { DefaultPredefinedLabel } from './defaultPresets'

@Component
export default class Month extends Vue {
  @Prop({ type: [Number, String], required: true }) month!: number | string
  @Prop({ type: [Number, String], required: true }) year!: number | string

  @InjectReactive() dateRangeSelection!: DefaultPredefinedLabel | string
  @InjectReactive() hoverDateRangeSelection!: Object

  @Inject() readonly allowPastDates!: boolean
  @Inject() readonly allowFutureDates!: boolean
  @Inject() readonly isSingleDate!: boolean
  @Inject() readonly isMaxDate!: Date | null
  @Inject() readonly isMinDate!: Date | null

  protected currentMonth: number = parseInt(this.$moment().format('M'))
  protected daysOfTheWeek: string[] = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
  protected hoverIndex: number = 0
  protected months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  protected minDate: Date | null = this.allowPastDates
    ? this.isMinDate
    : this.$moment(this.minDate)

  protected maxDate: Date | null = this.allowFutureDates
    ? this.isMaxDate
    : this.$moment(this.maxDate)

  protected dateHoverDebounced = _debounce(this.hoverDateRange, 300)

  get activeMonth(): string {
    return this.months[this.month]
  }

  get daysInMonth(): number {
    return this.$moment(`${this.year} ${this.month}`, 'YYYY M').daysInMonth()
  }

  get startingMonthDay(): number {
    return parseInt(
      this.$moment(`${this.year} ${this.month} 1`, 'YYYY M D').format('d')
    )
  }

  get endLastMonthDays(): number[] {
    const daysCount: number[] = []
    const lastDaysOfMonth = new Date(
      Date.UTC(this.year, this.month, -1 * this.startingMonthDay, 0)
    ).getDate()

    for (let i = 1; i <= this.startingMonthDay; i++) {
      daysCount.push(lastDaysOfMonth + i)
    }

    return daysCount
  }

  get startNextMonthDays(): number {
    return 42 - (this.startingMonthDay + this.daysInMonth)
  }

  get isCurrentMonth(): boolean {
    return this.month === this.currentMonth
  }

  isSelected(dateIndex: number, start: number, end?: number): string {
    if (this.isSingleDate && this.dateRangeSelection) {
      if (dateIndex === start) {
        return 'calendar-month__day--singleselection'
      } else {
        return ''
      }
    } else if (this.dateRangeSelection.from && !this.dateRangeSelection.to) {
      if (this.$moment(dateIndex).isSame(start, 'day')) {
        return 'calendar-month__day--singleselection'
      }
    } else if (this.dateRangeSelection.from && this.dateRangeSelection.to) {
      if (this.$moment(dateIndex).isSame(start, 'day')) {
        if (this.$moment(start).isSame(end, 'day')) {
          return 'calendar-month__day--start calendar-month__day--end'
        }
        return 'calendar-month__day--start'
      } else if (this.$moment(dateIndex).isSame(end, 'day')) {
        return 'calendar-month__day--end'
      }
    }

    return ''
  }

  isInRange(dateIndex: Date, from: Date, to: Date): string {
    if (
      this.$moment(dateIndex).isAfter(from) &&
      this.$moment(dateIndex).isSameOrBefore(to)
    ) {
      return 'calendar-month__day--in-range'
    } else {
      return ''
    }
  }

  hoverDateRange(index: number) {
    const start = this.$moment(this.dateRangeSelection.from)
    const dateCellIndex = this.$moment(
      `${this.year}-${this.month}-${index + 1}`
    )

    if (dateCellIndex.isSameOrAfter(start)) {
      this.$emit('hoverDateRange', { from: start, to: dateCellIndex })
    }
  }

  disabledDates(dateIndex: number, date: number, tense: string): string {
    if (tense === 'past') {
      return dateIndex < date ? 'calendar-month__day--disabled' : ''
    } else {
      return dateIndex > date ? 'calendar-month__day--disabled' : ''
    }
  }

  daysClass(index: number): string {
    const classNames: string[] = []
    const dateIndex = this.$moment(
      `${this.year}-${this.month}-${index + 1}`
    ).toDate()
    const today = this.$moment().startOf('day').toDate()
    const start = this.$moment(this.dateRangeSelection.from)
    const end = this.$moment(this.dateRangeSelection.to)
    const singleDate = this.$moment(this.dateRangeSelection)

    if (
      Object.keys(this.dateRangeSelection).length ||
      this.dateRangeSelection
    ) {
      this.isSingleDate
        ? classNames.push(this.isSelected(dateIndex, singleDate))
        : classNames.push(this.isSelected(dateIndex, start, end))
    }

    if (
      !this.isSingleDate &&
      this.dateRangeSelection.from &&
      this.dateRangeSelection.to
    ) {
      classNames.push(this.isInRange(dateIndex, start, end))
    }

    if (
      this.dateRangeSelection.from &&
      !this.dateRangeSelection.to &&
      this.hoverDateRangeSelection.from &&
      this.hoverDateRangeSelection.to
    ) {
      classNames.push(
        this.isInRange(
          dateIndex,
          this.hoverDateRangeSelection.from,
          this.hoverDateRangeSelection.to
        )
      )
    }

    if (!this.allowPastDates) {
      classNames.push(this.disabledDates(dateIndex, today, 'past'))
    }

    if (!this.allowFutureDates) {
      classNames.push(this.disabledDates(dateIndex, today, 'future'))
    }

    if (this.maxDate) {
      // @ts-ignore:next-line
      const date = this.$moment(this.maxDate)
      classNames.push(this.disabledDates(dateIndex, date, 'future'))
    }

    if (this.minDate) {
      // @ts-ignore:next-line
      const date = this.$moment(this.minDate)
      classNames.push(this.disabledDates(dateIndex, date, 'past'))
    }

    return classNames.join(' ')
  }

  @Emit('selectDate')
  selectDate(day: number) {
    return this.$moment(`${this.year}-${this.month}-${day}`, 'YYYY-MM-D')
  }

  @Emit('selectMonth')
  selectMonth(month: number, year: number) {
    return { month, year }
  }

  addCurrentDayClass(): void {
    const currentDay = this.$moment().format('DD')
    const currentDayButton =
      this.$refs.day &&
      this.$refs.day.find(
        (el) => el.attributes['data-date'].value === currentDay
      )

    if (currentDayButton) {
      currentDayButton.classList.add('calendar-month__day--today')
    }
  }

  removeCurrentDayClass(): void {
    const currentDayButton =
      this.$refs.day &&
      this.$refs.day.find((el) =>
        el.classList.contains('calendar-month__day--today')
      )

    if (currentDayButton) {
      currentDayButton.classList.remove('calendar-month__day--today')
    }
  }

  @Watch('month')
  onMonthChange() {
    if (this.isCurrentMonth) {
      this.addCurrentDayClass()
    } else {
      this.removeCurrentDayClass()
    }
  }

  mounted(): void {
    if (this.isCurrentMonth) {
      this.addCurrentDayClass()
    }
  }
}

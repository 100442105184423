





























import { Component, Vue, Prop, Inject, Emit } from 'nuxt-property-decorator'
import { DateRangeInput } from '@Core/@types/skyway'
import { defaultPresets, DefaultPredefinedLabel } from './defaultPresets'

@Component
export default class PresetRanges extends Vue {
  @Prop({ type: Object }) presetRanges?: {
    [key: string]: {
      label: string
      dateRange: DateRangeInput
    }
  }
  @Prop({ type: String, required: true }) activePresetName!: string

  @Inject() readonly isMaxDate!: Date | null
  @Inject() readonly isMinDate!: Date | null
  @Inject() readonly allowPastDates!: Boolean
  @Inject() readonly allowFutureDates!: Boolean

  private today: Date = new Date()
  private todayUTC = new Date(
    Date.UTC(
      this.today.getFullYear(),
      this.today.getMonth(),
      this.today.getDate()
    )
  )

  private minDate: Date | null = this.allowPastDates
    ? this.isMinDate
    : this.todayUTC

  private maxDate: Date | null = this.allowFutureDates
    ? this.isMaxDate
    : this.todayUTC

  get predefinedRanges(): {
    [key: string]: {
      label: string
      dateRange: DateRangeInput
    }
  } {
    const ranges = {}
    const presets = this.presetRanges || defaultPresets()

    for (const i in presets) {
      const item = presets[i]
      const plainItem = typeof item === 'function' ? item() : item

      if (this.maxDate || this.minDate) {
        if (
          this.minDate &&
          this.minDate.getTime() <= plainItem.dateRange.from.getTime()
        ) {
          ranges[i] = plainItem
        }

        if (
          this.maxDate &&
          this.maxDate.getTime() >= plainItem.dateRange.to.getTime()
        ) {
          ranges[i] = plainItem
        }
      } else {
        ranges[i] = plainItem
      }
    }

    return ranges
  }

  activePresetClass(label: string): string | Boolean {
    return label === this.activePresetName && 'preset-ranges__item--active'
  }

  @Emit('selectRange')
  selectRange(range?: DefaultPredefinedLabel): Object | null {
    return range
      ? {
          label: range.label,
          from: range.dateRange.from,
          to: range.dateRange.to,
        }
      : null
  }
}

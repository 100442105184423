















import { Component, Prop, Vue, Emit, Watch } from 'nuxt-property-decorator'
import ArrowLeftSvg from '@icons/material/svg/arrow-left.svg?inline'
import ArrowRightSvg from '@icons/material/svg/arrow-right.svg?inline'

@Component({
  components: {
    ArrowLeftSvg,
    ArrowRightSvg,
  },
})
export default class MonthPicker extends Vue {
  @Prop({ type: Array, required: true }) months!: Date[]

  @Watch('futureMonths')
  onMonthsChange(newVal) {
    if (newVal) {
      this.updateActive(this.formattedMonths[0])
    }
  }

  public active = null

  get formattedMonths() {
    return this.months.map((m) => {
      return this.$moment(m).format('MMM YY')
    })
  }

  updateActive(month) {
    this.active = month
    this.updateMonth()
  }

  @Emit('change')
  updateMonth() {
    return this.months?.find(
      (m) => this.$moment(m).format('MMM YY') === this.active
    )
  }
}































































































import { Component, Vue, Prop, Emit, namespace } from 'nuxt-property-decorator'
import DatePicker from '@UI/components/date-picker/DatePicker.vue'
import { DateRangeInput } from '@Core/@types/skyway'
import { FilterModel } from '@Events/store/events/state'
import FilterDropdownCheckboxes from '@UI/components/filters/FilterDropdownCheckboxes.vue'
import urlParams from '@UI/helpers/urlParams/urlParmas'
import CheckIcon from '~/static/images/icons/check.svg?inline'
import InfoIcon from '~/static/images/icons/info.svg?inline'

const events = namespace('events')

@Component({
  components: {
    DatePicker,
    FilterDropdownCheckboxes,
    CheckIcon,
    InfoIcon,
  },
})
export default class FilterBar extends Vue {
  $refs!: {
    datepicker: DatePicker
  }

  @Prop({ type: Array, required: true }) presetDateRangeOptions!: {
    label: string
    dateRange: DateRangeInput
  }[]
  @Prop({ type: Array, required: true }) focusOptions!: string[]
  @Prop({ type: Array, required: true }) ageOptions!: string[]
  @Prop({ type: Array, required: true }) typeOptions!: string[]
  @Prop({ type: Array, required: true }) accessOptions!: string[]
  @Prop({
    type: String,
    default: 'EventsGrid',
    validator(value) {
      return ['EventsGrid', 'EventsCalendarList'].includes(value)
    },
  })
  view!: string
  @Prop({ type: Object }) value!: FilterModel

  protected activeDateRangeButton: number | null = null

  @events.Mutation('SET_ACTIVE_FILTERS')
  protected setActiveFilters!: (payload: { key: string; value: any }) => void

  @events.Mutation('RESET_FILTERS')
  protected resetFilters!: () => void

  get toShow() {
    const map = {
      EventsGrid: {
        all: ['presetRanges', 'datePicker'],
        event: ['presetRanges', 'datePicker', 'type'],
        class: ['presetRanges', 'datePicker', 'focus', 'age'],
      },
      EventsCalendarList: {
        all: [''],
        event: ['type'],
        class: ['focus', 'age'],
      },
    }

    const key = this.value.eventTypes.includes('event')
      ? 'event'
      : this.value.eventTypes.includes('class')
      ? 'class'
      : 'all'

    return map[this.view][key]
  }

  get eventTypesFilterValue(): string | null {
    if (this.value.eventTypes.length > 0) {
      return this.value.eventTypes[0]
    }

    return null
  }

  get dateRange() {
    return this.value.dateRange
  }

  set dateRange(newVal) {
    this.setActiveFilters({ key: 'dateRange', value: newVal })
    this.$emit('apply-filters')
  }

  get eventAge() {
    return this.value.age
  }

  set eventAge(newVal) {
    this.updateUrl('age', newVal)
    this.setActiveFilters({ key: 'age', value: newVal })
    this.$emit('apply-filters')
  }

  get eventFocus() {
    return this.value.focus
  }

  set eventFocus(newVal) {
    this.updateUrl('focus', newVal)
    this.setActiveFilters({ key: 'focus', value: newVal })
    this.$emit('apply-filters')
  }

  get accessibility() {
    return this.value.accessibility
  }

  set accessibility(newVal) {
    this.updateUrl('access', newVal)
    this.setActiveFilters({ key: 'accessibility', value: newVal })
    this.$emit('apply-filters')
  }

  get eventTags() {
    return this.value.eventTags
  }

  set eventTags(newVal) {
    this.updateUrl('cat', newVal)

    this.setActiveFilters({ key: 'eventTags', value: newVal })
    this.$emit('apply-filters')
  }

  get free() {
    return this.value.free
  }

  set free(newVal) {
    this.updateUrl('free', newVal ? ['true'] : [])
    this.setActiveFilters({ key: 'free', value: newVal })
    this.$emit('apply-filters')
  }

  async updateUrl(paramName, value) {
    const app = this
    await urlParams.update(app, paramName, value)
  }

  applyDateRange(payload: DateRangeInput) {
    const from = this.$moment(payload.from).startOf('day').toDate()
    const to = this.$moment(payload.to).endOf('day').toDate()

    this.activeDateRangeButton = null
    this.dateRange = { from, to }
  }

  clearDateRange(): void {
    this.activeDateRangeButton = null
    this.dateRange = {
      from: undefined,
      to: undefined,
    }

    if (this.$refs.datepicker) {
      this.$refs.datepicker.clear()
    }
  }

  applyPresetDateRange(dateRange: DateRangeInput, index: number) {
    if (
      this.activeDateRangeButton === null ||
      this.activeDateRangeButton !== index
    ) {
      this.$refs.datepicker.selectRange(dateRange)
      this.$refs.datepicker.applyDates()
      this.applyDateRange(dateRange)
      this.activeDateRangeButton = index
    } else {
      this.clearDateRange()
    }
  }

  @Emit()
  clearFilters() {
    this.clearDateRange()
    this.resetFilters()
    return 'clear-filters'
  }

  fetch() {
    const { query } = this.$route

    if (query?.cat) {
      this.setActiveFilters({
        key: 'eventTags',
        value: urlParams.parse(query.cat),
      })
    }

    if (query?.focus) {
      this.setActiveFilters({
        key: 'focus',
        value: urlParams.parse(query.focus),
      })
    }

    if (query?.access) {
      this.setActiveFilters({
        key: 'accessibility',
        value: urlParams.parse(query.access),
      })
    }

    if (query?.age) {
      this.setActiveFilters({ key: 'age', value: urlParams.parse(query.age) })
    }

    if (query?.free) {
      this.setActiveFilters({ key: 'free', value: true })
    }
  }
}

import { Moment } from 'moment'

/**
 * Calculate age using dob
 *
 * Pass in a second date to get the age on that date
 *
 * @param   { string } dateOfBirth
 * @param   { string } dateToCheck
 * @param   { dateHelper Implementation }
 *
 * @return  { number } - age in years
 */
export const calculateAgeOnDate = (
  dateOfBirth: string,
  dateToCheck: string,
  dateHelper: (date?: string) => Moment
): number => {
  let birthday = dateHelper(dateOfBirth)
  let now = dateToCheck ? dateHelper(dateToCheck) : dateHelper()

  let ageDiff = now.diff(birthday, 'year')

  return ageDiff
}

export const calculateAgeToday = (
  dateOfBirth: string,
  dateHelper: (date?: string) => Moment
): number => {
  const birthday = dateHelper(dateOfBirth)
  const now = dateHelper()

  const ageDiff = now.diff(birthday, 'year')

  return ageDiff
}

export const dateFormatter = (start: Moment, end: Moment) => {
  if (start.format('DD MMM YYYY') !== end.format('DD MMM YYYY')) {
    if (start.format('YYYY') !== end.format('YYYY')) {
      return `${start.format('DD MMM YYYY')}–${end.format('DD MMM YYYY')}`
    } else {
      return `${start.format('DD MMM')}–${end.format('DD MMM YYYY')}`
    }
  } else {
    return start.format('DD MMM YYYY')
  }
}

export const singleDateFormatter = (
  date: Moment,
  mq: string = 'lg',
  noTime: boolean = false
) => {
  let output
  const format = mq === 'sm' ? 'DD/MM/YY' : 'ddd DD MMM YY'

  if (noTime) {
    output = date.format(`${format}`)
  } else {
    output =
      date.format('mm') !== '00'
        ? date.format(`${format} h:mma`)
        : (output = date.format(`${format} ha`))
  }

  return output
}

export const timeFormatter = (date: Moment) => {
  return date.format('mm') !== '00' ? date.format(`h:mma`) : date.format(`ha`)
}

export default dateFormatter
























import { Component, Prop, VModel, Vue } from 'nuxt-property-decorator'
import CheckIcon from '~/static/images/icons/check.svg?inline'

@Component({
  components: {
    CheckIcon,
  },
})
export default class FiltersCheckboxGroup extends Vue {
  @Prop({ type: Array, required: true }) choices!: {
    label: string
    value: string | boolean
  }[]
  @VModel({ type: Array }) checkedValues!: string[]
}

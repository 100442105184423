























import { Component, Prop, Vue } from 'nuxt-property-decorator'
import CloseSvg from '~/static/images/icons/close.svg?inline'

@Component({
  components: {
    CloseSvg,
  },
})
export default class CheckboxFilters extends Vue {
  @Prop({ type: Array, required: true }) filters!: { value: any; label: any }[]
  @Prop({ type: Array, default: () => [] }) value!: string[]

  get selection() {
    return this.value
  }

  set selection(newVal) {
    this.$emit('input', newVal)
    this.$emit('filters-applied')
  }
}














































import {
  Component,
  Emit,
  Prop,
  mixins,
  namespace,
} from 'nuxt-property-decorator'
import PaginatedItemHandler from '@UI/components/lists/helpers/PaginatedItemHandler.vue'
import InfiniteList from '@UI/components/lists/InfiniteList.vue'
import DateCard from '@Events/components/events/DateCard.vue'
import MonthPicker from '@UI/components/date-picker/MonthPicker.vue'
import NoResults from '@Events/components/events/search-events/NoResults.vue'
import ToggleView from '@UI/components/toggle-view/ToggleView.vue'
import LoadingWheel from '@UI/components/loader/LoadingWheel.vue'
import { Event, Instance } from '@Core/@types/skyway'

const events = namespace('events')

@Component({
  inheritAttrs: false,
  components: { NoResults, DateCard, InfiniteList, MonthPicker, LoadingWheel },
})
export default class EventsCalendarList extends mixins<PaginatedItemHandler>(
  PaginatedItemHandler
) {
  @Prop({ type: [Object, Array] }) instances?: {
    [key: string]: Instance[]
  }
  @Prop({ type: String, default: '' }) searchTerm!: string
  @Prop() toggleView!: ToggleView
  @Prop({ type: String, default: 'empty-events' }) noResultsType!: string
  @Prop({ type: String, required: true }) currentMonth!: string
  @Prop({ type: Boolean }) loading!: boolean
  @Prop({ type: Boolean, default: false }) isSeriesPage!: boolean
  @Prop({ type: Array, default: () => [] }) events!: Event[]
  @Prop({ type: Array }) availability?: Event[]

  @events.Mutation('RESET_FILTERS')
  protected resetFilters!: () => void

  get months(): string[] | [] {
    const monthsArr: string[] = []

    const monthsToGenerate = this.isSeriesPage ? this.monthsInSeries : 12

    for (let i = 0; i < monthsToGenerate; i++) {
      const monthAtIndex = this.isSeriesPage
        ? this.$moment(this.events[0] && this.events[0].first_date, 'YYYY-MM')
            .date(1)
            .add(i, 'months')
            .format('YYYY-MM-DD')
        : this.$moment().date(1).add(i, 'months').format('YYYY-MM-DD')

      monthsArr.push(monthAtIndex)
    }

    return monthsArr
  }

  get monthsInSeries(): number {
    const firstDate = this.events[0] && this.events[0].first_date
    const lastDate = this.events[this.events.length - 1].last_date

    if (firstDate && lastDate) {
      return this.$moment(firstDate).diff(this.$moment(lastDate), 'months') + 2
    } else {
      return 12
    }
  }

  get currentMonthInstances() {
    if (this.instances && !this.loading) {
      // remove private event instances
      const filteredInstances = this.filterPrivateEvents(this.instances)
      return this.instanceAvailability.length > 0
        ? this.mapAvailabilityToInstances(
            filteredInstances,
            this.instanceAvailability
          )
        : filteredInstances
    } else {
      return null
    }
  }

  filterPrivateEvents(data) {
    const filteredData = {}

    for (const [date, instances] of Object.entries(data)) {
      filteredData[date] = instances.filter((instance) => {
        return instance.event.private !== true
      })
    }

    return filteredData
  }

  mapAvailabilityToInstances(
    instances: { [key: string]: Instance[] },
    availability: Instance[]
  ): { [key: string]: Instance[] } {
    const output: { [key: string]: Instance[] } = {}

    Object.entries(instances).forEach(([key, instanceArray]) => {
      instanceArray.forEach((instance) => {
        const matchingAvailability = availability.find(
          (availInstance) =>
            availInstance.instance_ref === instance.instance_ref
        )

        if (matchingAvailability) {
          const mergedInstance = {
            ...instance,
            ...matchingAvailability,
          }

          if (!output[key]) {
            output[key] = []
          }

          const index = output[key].findIndex(
            (i) => i.instance_ref === mergedInstance.instance_ref
          )

          if (index !== -1) {
            output[key][index] = mergedInstance
          } else {
            output[key].push(mergedInstance)
          }
        } else {
          if (!output[key]) {
            output[key] = []
          }
          const index = output[key].findIndex(
            (i) => i.instance_ref === instance.instance_ref
          )
          if (index !== -1) {
            output[key][index] = instance
          } else {
            output[key].push(instance)
          }
        }
      })
    })

    return output
  }
  get instanceAvailability() {
    return this.availability
      ? this.availability.reduce((acc: Instance[], event) => {
          if (event && event.instances) {
            for (const instance of event.instances) {
              acc.push(instance as Instance)
            }
          }
          return acc
        }, [])
      : []
  }

  @Emit()
  loadDateAvailability(event_ref: string) {
    return event_ref
  }

  @Emit()
  handleMonthChange(val) {
    const el = this.toggleView && this.toggleView.$el

    if (el) {
      el.scrollIntoView()
    }

    return val
  }

  onReset() {
    this.resetFilters()
  }
}

































import { Component, Prop, mixins, namespace } from 'nuxt-property-decorator'
import PaginatedItemHandler from '@UI/components/lists/helpers/PaginatedItemHandler.vue'
import InfiniteList from '@UI/components/lists/InfiniteList.vue'
import EventCard from '@Events/components/events/EventCard.vue'
import SeriesCard from '@Events/components/series/SeriesCard.vue'
import NoResults from '@Events/components/events/search-events/NoResults.vue'
import { Event } from '@Core/@types/skyway'

const events = namespace('events')

@Component({
  inheritAttrs: false,
  components: { EventCard, SeriesCard, InfiniteList, NoResults },
})
export default class EventsGrid extends mixins<PaginatedItemHandler>(
  PaginatedItemHandler
) {
  @Prop({ type: Array, required: true }) items!: Event[]
  @Prop({ type: String, default: '' }) searchTerm!: string
  @Prop({ type: String, default: 'empty-events' }) noResultsType!: string

  @events.Mutation('RESET_FILTERS')
  public resetFilters!: () => void

  onReset() {
    this.$emit('clear-search-term')
    this.resetFilters()
  }
}

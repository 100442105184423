import { DateRangeInput } from '@Core/@types/skyway'

export interface DefaultPredefinedLabel {
  label: string
  dateRange: DateRangeInput
}

export const defaultPresets = () => {
  const date = new Date()

  return {
    today(): DefaultPredefinedLabel {
      const from = new Date()
      const to = new Date()
      return {
        label: 'Today',
        dateRange: {
          from,
          to,
        },
      }
    },

    nextWeek(): DefaultPredefinedLabel {
      const from = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      )
      const to = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate() + 7)
      )
      return {
        label: 'Next 7 days',
        dateRange: {
          from,
          to,
        },
      }
    },

    nextMonth(): DefaultPredefinedLabel {
      const from = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      )
      const to = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate() + 30)
      )
      return {
        label: 'Next 30 days',
        dateRange: {
          from,
          to,
        },
      }
    },
  }
}

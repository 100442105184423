




















































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { ISeriesCard } from '@Core/@types/types'
import { CmsImage } from '@Core/@types/skyway'

import {
  CMSEventImageTransformer,
  CMSImageTransformer,
} from '@Marketing/transformers/image'

@Component({
  inheritAttrs: false,
})
export default class SeriesCard extends Vue {
  @Prop({ type: Object, required: true }) data!: ISeriesCard
  @Prop({ type: Boolean, default: false }) isFeaturedSeries!: Boolean
  @Prop({ type: String }) seriesName!: String
  @Prop({ type: String }) seriesColor?: string

  get pageRedirectUrl(): string {
    if (this.isFeaturedSeries) {
      return `/series/${this.data.alias}`
    } else {
      return `/series/${this.data.alias}`
    }
  }

  get backgroundImage(): CmsImage | false {
    const images = this.data.images
    if (images) {
      return images ? CMSEventImageTransformer(images) : false
    }
    return false
  }

  get seriesImage(): CmsImage | false {
    if (this.isFeaturedSeries) {
      const image =
        this.data.seriesImage && this.data.seriesImage.length
          ? this.data.seriesImage
          : this.data.image
      const title = this.data.seriesTitle
        ? this.data.seriesTitle
        : this.data.title
      if (image && title) {
        const featuredImage = {}
        featuredImage.medium = {}
        featuredImage.medium = {
          url: image,
        }
        return CMSImageTransformer({ sizes: featuredImage, alt: title })
      }
    }
  }

  get partnerImage(): CmsImage | false {
    const image = this.data.partnerImage
    if (image) {
      const featuredImage = {}
      featuredImage.small = {}
      featuredImage.small = {
        url: image,
      }
      return CMSImageTransformer({ sizes: featuredImage })
    }
    return false
  }

  get date(): string {
    const seriesData = this.data.instances

    let fullDate = ''
    if (this.data.instances) {
      const startDate = this.$moment(seriesData[0].first_date).format(
        'ddd D MMM YY'
      )
      const endDate = this.$moment(
        seriesData[seriesData.length - 1].first_date
      ).format('ddd D MMM YY')

      fullDate = `${startDate} - ${endDate}`
    }
    return this.isFeaturedSeries
      ? fullDate
      : this.$moment(this.data.first_date).format('ddd D MMM YY')
  }

  get backgroundColorClass(): string | undefined {
    return this.seriesColor && `featured-card--${this.seriesColor}`
  }
}





import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class PaginatedItemHandler extends Vue {
  @Prop({ type: Number, default: 9 }) perPage!: number

  protected increment: number = 0
  protected currentPage: number = 1

  onLoadingChange() {}

  onListResized() {}

  onLoadMore() {
    this.currentPage++
  }
}

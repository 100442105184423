import { Event, Instance, ModeOfSale } from '@Core/@types/skyway'

export enum InstanceStates {
  PastInstance = 'has_past',
  Staff = 'staff_test',
  OffSale = 'off_sale',
  OnSale = 'on_sale',
  ComingSoon = 'coming_soon',
  PreSale = 'pre_sale',
  Promo = 'promo',
  Members = 'members',
  Priority = 'priority',
  AdvancePriority = 'advance_priority',
  NotFound = 'not_found',
  LowAvailability = 'low_availability',
  SoldOut = 'sold_out',
  Free = 'free',
}

export const eventHasPassed = (
  event: Event,
  modesOfSale: ModeOfSale[],
  moment
) => {
  const thisMorning = moment().set({
    hours: 0,
    minutes: 0,
  })

  const lastDate = moment(event.last_date).set({ hours: 23, minutes: 59 })

  return thisMorning.isAfter(lastDate)
}

export const validateModeOfSale = (
  input: Instance | Event,
  customer_mos,
  moment
): boolean => {
  if (input && input.modes_of_sale && input.modes_of_sale.length) {
    const input_mos = input.modes_of_sale.find(
      (mos) => mos && mos.mode_of_sale_ref === customer_mos
    )

    if (
      input_mos &&
      moment().isBetween(input_mos.start_date, input_mos.end_date)
    ) {
      return true
    }
  }
  return false
}

/**
 * We don't want to staff MOSs as open
 * for the purpose of resolving the event state
 */
const STAFF_MOS: string[] = ['42', '31', '30', '27', '12', '4', '3']

/**
 * Given an instance or event
 * Return all open modes of sale, ordered by start date
 */
export const openModesOfSale = (
  input: Instance | Event,
  moment
): ModeOfSale[] => {
  if (input && input.modes_of_sale && input.modes_of_sale.length) {
    return (
      (input.modes_of_sale as ModeOfSale[])
        .filter((mos) => {
          return (
            mos &&
            moment().isBetween(
              moment.utc(mos.start_date),
              moment.utc(mos.end_date)
            ) &&
            !STAFF_MOS.includes(mos.mode_of_sale_ref)
          )
        })
        .sort((a, b) =>
          moment(a.start_date).isBefore(moment(b.start_date)) ? 1 : -1
        ) || []
    )
  }
  return []
}

export const hasOpenModeOfSale = (input: Instance | Event, moment): boolean => {
  return openModesOfSale(input, moment).length > 0
}

export const hasForthcomingModeOfSale = (
  input: Instance | Event,
  moment
): boolean => {
  if (input && input.modes_of_sale && input.modes_of_sale.length) {
    const hasForthcomingMos = input.modes_of_sale.filter(
      (mos) =>
        mos && moment().add(7, 'days').isBetween(mos.start_date, mos.end_date)
    )

    if (hasForthcomingMos && hasForthcomingMos.length) {
      return true
    }
  }
  return false
}

export const parseModeOfSaleDescription = (mos: ModeOfSale): string | null => {
  switch (Number(mos.mode_of_sale_ref)) {
    case 5:
      return 'General Public' //'Internet'
    case 10:
      return 'Members' //'Web Memberships'
    case 33:
      return 'Promo Price'
    case 36:
      return 'Advance Priority' //'Web Advance Priority'
    case 37:
      return 'Access' //'Internet Access'
    case 39:
      return 'Priority' //'Web Priority'
    case 42:
      return 'Staff' // 'Staff test MOS'
    default:
      return null
  }
}

/**
 * Get the overall event state by checking each open instance.mode_of_sale
 * present on the event. An open MOS should have a start date before now and an
 * end date after now.
 *
 * Return the lowest open MOS
 *
 * We could consider additional validation here to remove any MOSs that are not
 * open before we return
 */
export const parseEventState = (
  mos: ModeOfSale[],
  promo_code?: string
): InstanceStates => {
  const map = new Map([
    ['5', InstanceStates.OnSale],
    ['10', InstanceStates.Priority],
    ['33', InstanceStates.Promo],
    ['36', InstanceStates.AdvancePriority],
    ['39', InstanceStates.Priority],
    ['42', InstanceStates.Staff],
  ])

  let states: InstanceStates[] = []

  for (const m of mos.sort(
    (a, b) => parseInt(a.mode_of_sale_ref) - parseInt(b.mode_of_sale_ref)
  )) {
    const state = map.get(m.mode_of_sale_ref)
    if (state) {
      if (state === InstanceStates.Promo && !promo_code) {
      } else {
        states.push(state)
      }
    }
  }

  if (states && states[0]) {
    return states[0]
  } else if (mos.length < 1) {
    return InstanceStates.PreSale
  } else {
    return InstanceStates.OffSale
  }
}











































































import { Component, Prop, VModel, Vue } from 'nuxt-property-decorator'
import ArrowDropDownSvg from '~/static/images/icons/arrow-drop-down.svg?inline'

export interface FilterOption {
  label: string
  value: string | number
}

@Component({
  components: {
    ArrowDropDownSvg,
  },
})
export default class MultiSelectDropdown extends Vue {
  @Prop({ type: Array }) options?: Array<FilterOption>
  @Prop({ type: String }) label?: string
  @Prop({ type: String }) defaultOptionLabel?: string
  @Prop({ type: Array, default: () => [] }) value!: Array<any>

  @VModel({ type: Array, default: () => [] }) selection!: Array<any>

  public isOpen: boolean = false

  onClickOpen(): void {
    this.isOpen = !this.isOpen
    document.addEventListener('click', this.handleClickOutsideComponent)
  }

  onClickClose(): void {
    this.isOpen = false
    document.removeEventListener('click', this.handleClickOutsideComponent)
  }

  onClickApply(): void {
    this.$emit('input', this.value)
    this.isOpen = false
  }

  handleOtherOpenened(id: number): void {
    // @ts-ignore:next-line
    if (this._uid !== id) {
      this.onClickClose()
    }
  }

  get filterDisplay(): string {
    const label = this.defaultOptionLabel
    return label ?? ''
  }

  handleClearFilters(): void {
    this.$emit('input', [])
  }

  handleClickOutsideComponent(event: Event): void {
    const dropdownEl = this.$refs.dropdown as Element
    const target = event.target as Node

    if (dropdownEl && target && !dropdownEl.contains(target)) {
      this.onClickClose()
    }
  }
}



























































































































import { Component, Emit, mixins } from 'nuxt-property-decorator'
import FilterBar from '@UI/components/filters/FilterBar.vue'
import FiltersCheckboxGroup from '@UI/components/filters/FiltersCheckboxGroup.vue'
import MobileRangeFields from '@UI/components/date-picker/MobileRangeFields.vue'
import _debounce from 'lodash/debounce'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { DateRange } from '@UI/components/date-picker/defaultPresets'
import FilterSvg from '~/static/images/icons/filter.svg?inline'
import CloseSvg from '~/static/images/icons/close.svg?inline'

@Component({
  components: {
    FilterSvg,
    CloseSvg,
    FiltersCheckboxGroup,
    MobileRangeFields,
  },
})
export default class FilterPopup extends mixins<FilterBar>(FilterBar) {
  protected showFilters: boolean = false
  protected debouncedOnDateRangeApplied: Function = _debounce(
    this.onDateRangeApplied,
    500
  )
  protected dialogEl?: HTMLDialogElement
  protected modalEl?: HTMLDialogElement

  get hasAppliedFilters(): boolean {
    let result = false
    for (const fil of Object.keys(this.value)) {
      if (this.value[fil] && this.value[fil].length > 0) {
        result = true
      }
    }

    return result
  }

  onDateRangeApplied(payload: DateRange) {
    if (payload.from && payload.to) {
      const formatted = {
        from: this.$moment.utc(payload.from).format('YYYY-MM-DD'),
        to: this.$moment.utc(payload.to).format('YYYY-MM-DD'),
      }
      this.$emit('change-week', formatted)
    }
  }

  onMobileDateApplied(payload: DateRange) {
    this.debouncedOnDateRangeApplied(payload)
  }

  open(): void {
    if (this.dialogEl) {
      this.dialogEl.showModal()

      disableBodyScroll(this.dialogEl)
      this.dialogEl.classList.remove('close')
    }
  }

  close(): void {
    if (this.dialogEl) {
      this.dialogEl.classList.add('close')

      const closeDialog = () => {
        this.dialogEl!.close()
        clearAllBodyScrollLocks()

        this.dialogEl!.removeEventListener('animationend', closeDialog)
      }

      this.dialogEl.addEventListener('animationend', closeDialog)
    }
  }

  @Emit()
  applyFilters() {
    this.close()

    return 'apply-filters'
  }

  mounted(): void {
    this.dialogEl = this.$refs.modal as HTMLDialogElement
  }

  beforeDestroy(): void {
    clearAllBodyScrollLocks()
  }
}













import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import urlParams from '~/modules/@skyway/ui/src/helpers/urlParams/urlParmas'

type Message = {
  title: String
  subtitle: String | null
  buttonLabel: String
}

@Component
export default class NoResults extends Vue {
  @Prop({ type: String }) searchTerm?: string
  @Prop({
    type: String,
    validator(value) {
      return [
        'empty-events',
        'empty-filtered-events',
        'empty-searched-events',
        'empty-filtered-searched-events',
      ].includes(value)
    },
    required: true,
  })
  type!: string

  @Emit()
  reset() {
    urlParams.reset(this)
    return 'reset'
  }

  get message(): Message {
    let title!: string, subtitle!: string | null, buttonLabel!: string

    switch (this.type) {
      case 'empty-events':
        title = 'No upcoming events right now'
        subtitle = 'Please check back later'
        buttonLabel = 'back to home'
        break
      case 'empty-filtered-events':
        title = 'No events match your filters'
        subtitle = null
        buttonLabel = 'reset all filters'
        break
      case 'empty-searched-events':
        title = `No results for ${this.searchTerm}`
        subtitle = "Check that you haven't misspelled your search term."
        buttonLabel = 'reset'
        break
      case 'empty-filtered-searched-events':
        title = 'No results'
        subtitle = null
        buttonLabel = 'reset all'
        break
    }

    return { title, subtitle, buttonLabel }
  }
}

















import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import FilterBar from '@UI/components/filters/FilterBar.vue'
import FilterPopup from '@UI/components/filters/FilterPopup.vue'
import * as dateRanges from '@UI/helpers/date-time/date-time'
import FilterVariantSvg from '@icons/material/svg/filter-variant.svg?inline'
import CloseIcon from '@icons/material/svg/close.svg?inline'
import CheckboxGroup from '@UI/components/forms/fields/CheckboxGroup.vue'
import RangeField from '@UI/components/forms/fields/RangeSlider.vue'
import EventDateFilter from '@Events/components/events/EventDateFilter.vue'
import { FilterModel } from '@Events/store/events/state'
import { DateRangeInput } from '@Core/@types/skyway'

/**
 * Event Filters
 * Combination of different filter types
 */
@Component({
  components: {
    FilterBar,
    FilterPopup,
    FilterVariantSvg,
    CheckboxGroup,
    RangeField,
    EventDateFilter,
    CloseIcon,
  },
})
export default class EventFilters extends Vue {
  @Prop({
    type: String,
    default: 'EventsGrid',
    validator(value) {
      return ['EventsGrid', 'EventsCalendarList'].includes(value)
    },
  })
  view!: string
  @Prop({ type: Object }) value!: FilterModel
  @Prop({ type: String, default: 'FilterBar' }) version!: string

  get componentName(): string {
    return this.version
  }

  protected dateRangeUpdating: boolean = true
  protected date: any = this.$moment()
  protected eventFilters: Element | null = null

  protected presetDateRangeOptions: {
    label: string
    dateRange: DateRangeInput
  }[] = [
    {
      label: 'This weekend',
      dateRange: dateRanges.thisWeekend(this.$moment),
    },
    {
      label: 'Next 2 weeks',
      dateRange: dateRanges.nextFortnight(this.$moment),
    },
    {
      label: 'Next 30 days',
      dateRange: dateRanges.nextMonth(this.$moment),
    },
  ]

  protected typeOptions = [
    {
      label: 'Music',
      value: 'music',
    },
    {
      label: 'Spoken word',
      value: 'spoken word',
    },
    {
      label: 'Theatre',
      value: 'theatre',
    },
    {
      label: 'Talk',
      value: 'talk',
    },
    {
      label: 'Circus',
      value: 'circus',
    },
    {
      label: 'Film',
      value: 'film',
    },
    {
      label: 'Special',
      value: 'special',
    },
    {
      label: 'Comedy',
      value: 'comedy',
    },
  ]

  protected focusOptions = [
    {
      label: 'Media',
      value: 'media',
    },
    {
      label: 'Music',
      value: 'music',
    },
    {
      label: 'Performing Arts',
      value: 'performing arts',
    },
    {
      label: 'Artistic Development',
      value: 'artistic development',
    },
    {
      label: 'Professional Development',
      value: 'professional development',
    },
  ]

  protected ageOptions = [
    {
      label: '11-14',
      value: '11-14',
    },
    {
      label: '15-17',
      value: '15-17',
    },
    {
      label: '18-25',
      value: '18-25',
    },
    {
      label: '18-30',
      value: '18-30',
    },
  ]

  protected accessOptions = [
    {
      label: 'Relaxed',
      value: 'relaxed',
    },
    {
      label: 'Signed',
      value: 'signed',
    },
    {
      label: 'Subtitled',
      value: 'subtitled',
    },
    {
      label: 'Audio described',
      value: 'audio described',
    },
    {
      label: 'Autism friendly',
      value: 'autism friendly',
    },
  ]

  @Emit('close')
  close(): null {
    return null
  }

  @Emit('apply-filters')
  applyFilters(): void {
    this.close()
  }

  @Emit('clear-filters')
  clearFilters(): void {
    this.close()
  }
}

import { DateRange } from '@UI/components/date-picker/defaultPresets'

export const thisWeekend = (moment): DateRange => {
  const from = moment().day(6).startOf('day').toDate()
  const to = moment().day(7).endOf('day').toDate()

  return { from, to }
}

export const nextFortnight = (moment): DateRange => {
  const from = moment().toDate()
  const to = moment().add(2, 'weeks').toDate()

  return { from, to }
}

export const nextMonth = (moment): DateRange => {
  const from = moment().toDate()
  const to = moment().add(1, 'months').toDate()

  return { from, to }
}

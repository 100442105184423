


















import { Component, Vue, namespace } from 'nuxt-property-decorator'
import { Series } from '@Core/@types/skyway'

export type ViewOptions = 'EventsGrid' | 'EventsCalendarList'

const events = namespace('events')
const instances = namespace('instances')

@Component
export default class SeriesContainer extends Vue {
  @events.Action
  protected getAndCommitSeriesByAlias!: (alias) => Promise<Series[]>

  @events.State('singleSeries')
  series?: Series

  @instances.Mutation('RESET')
  protected resetInstances!: () => void

  public view: ViewOptions = 'EventsGrid'
  public currentMonth: string = this.$moment().format('YYYY-MM')

  changeView(val: ViewOptions) {
    this.view = val
  }

  get sponsors() {
    if (this.series && this.series.sponsors && this.series.sponsors[0].name) {
      const eventSponsors = this.series.sponsors.map((s) => {
        return s.logo
          ? {
              title: s.name,
              caption: s.name,
              level: s.level,
              image: {
                sizes: {
                  large: {
                    url: `${this.$config.get('ASSET_PATH')}/${s.logo}`,
                  },
                },
              },
              link: s.website_url ? { url: s.website_url } : null,
            }
          : {
              title: s.name,
              caption: s.name,
              level: s.level,
              extra: { type: s.name, name: s.name },
              link: s.website_url ? { url: s.website_url } : null,
            }
      })
      return {
        title: 'Thanks to our sponsors',
        sponsors: eventSponsors.filter((s) => s),
      }
    } else {
      return null
    }
  }

  get invalidSeries() {
    return !this.series || this.series.id === null
  }

  get sponsorsLast() {
    if (this.series && this.series.sponsors && this.series.extra) {
      return this.series.extra.sponsors_block_position === 'below'
    } else {
      return false
    }
  }

  handleMonthChange(val) {
    this.currentMonth = this.$moment(val).format('YYYY-MM')
  }

  /**
   * See https://app.asana.com/0/1205657949822118/1205726611990535
   */
  get showListByDefault() {
    return Boolean(this.series?.alias === 'kagami')
  }

  async loadSeries() {
    this.resetInstances()
    if (!this.series || this.series.alias !== this.$route.params.id) {
      await this.getAndCommitSeriesByAlias(this.$route.params.id)
    }
  }

  async mounted() {
    await this.loadSeries()

    this.setUpDefaults()
  }

  setUpDefaults() {
    if (this.showListByDefault) {
      this.changeView('EventsCalendarList')
    }

    if (this.series && this.series.events && this.series.events[0]) {
      this.handleMonthChange(
        this.$moment(this.series.events[0]?.first_date).format('YYYY-MM')
      )
    }
  }

  async fetch(): Promise<void> {
    await this.loadSeries()
    await this.$nextTick()

    if (process.client) {
      setTimeout(() => {
        this.setUpDefaults()
      }, 500)
    }
  }
}


















































































































































































import {
  Component,
  Prop,
  Vue,
  Emit,
  ProvideReactive,
  Provide,
  Watch,
} from 'nuxt-property-decorator'
import Month from '@UI/components/date-picker/Month.vue'
import MobileCalendar from '@UI/components/date-picker/MobileCalendar.vue'
import CloseIcon from '@icons/material/svg/close-circle.svg?inline'
import PresetRanges from '@UI/components/date-picker/PresetRanges.vue'
import { DateRange } from './defaultPresets'
import ArrowDropDownSvg from '~/static/images/icons/arrow-drop-down.svg?inline'
import ArrowLeftSvg from '~/static/images/icons/arrow-left.svg?inline'
import ArrowRightSvg from '~/static/images/icons/arrow.svg?inline'

@Component({
  components: {
    ArrowDropDownSvg,
    ArrowLeftSvg,
    ArrowRightSvg,
    CloseIcon,
    Month,
    MobileCalendar,
    PresetRanges,
  },
})
export default class DatePicker extends Vue {
  @Prop({ type: String, default: 'Select date(s)' }) placeholder!: string
  @Prop({ type: String, default: 'YYYY-MM-DD' }) format?: string
  @Prop({ type: String }) label?: string
  @Prop({ type: String, default: 'YYYY-MM-DD' }) displayFormat?: string
  @Prop({ type: Date, default: null }) maxDate?: Date | null
  @Prop({ type: Date, default: null }) minDate?: Date | null
  @Prop({ type: Boolean, default: false }) singleDatePicker?: boolean
  @Prop({ type: Boolean, default: true }) autoApply?: boolean
  @Prop({ type: String, default: '–' }) dateSeparator?: string
  @Prop({ type: String, default: 'button' }) buttonClass?: string
  @Prop({ type: String, default: 'button--dark-grey' })
  applyButtonClass?: string
  @Prop({ type: String }) cancelButtonClass?: string
  @Prop({ type: Boolean, default: false }) disablePastDates?: boolean
  @Prop({ type: Boolean, default: false }) disableFutureDates?: boolean
  @Prop({ type: Object }) presetRanges?: object
  @Prop({ type: Boolean, default: false }) isLoading?: boolean
  @Prop({ type: Boolean, default: false }) showClearButton?: boolean
  @Prop({ type: [Object, Boolean], default: false }) presetRangeSelection?:
    | DateRange
    | boolean
  @Prop({ type: Boolean, default: true }) showPresetRange!: boolean

  @ProvideReactive() dateRangeSelection: any = {}
  @ProvideReactive() hoverDateRangeSelection: Object = {}

  @Provide() allowPastDates: Boolean = !this.disablePastDates
  @Provide() allowFutureDates: Boolean = !this.disableFutureDates
  @Provide() isSingleDate: boolean | undefined = this.singleDatePicker
  @Provide() isMaxDate: Date | null | undefined = this.maxDate
  @Provide() isMinDate: Date | null | undefined = this.minDate

  protected isOpen: Boolean = false
  protected months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  protected daysOfTheWeek = ['M', 'T', 'W', 'T', 'F', 'S', 'S']
  protected currentYear = parseInt(this.$moment().format('YYYY'))
  protected nextYear = parseInt(this.$moment().add(1, 'year').format('YYYY'))
  protected currentMonth = parseInt(this.$moment().format('M'))
  protected nextMonth = parseInt(this.$moment().add(1, 'month').format('M'))
  protected currentDay: Date = this.$moment().toDate()
  protected dateIsApplied: boolean = false
  protected activePresetName: string = ''

  @Watch('presetRangeSelection')
  onPropertyChanged(newVal, oldVal) {
    if (!oldVal || (newVal.to !== oldVal.to && newVal.from !== oldVal.from)) {
      this.dateRangeSelection = this.presetRangeSelection
      this.applyDates()
    }
  }

  get currentActiveMonth(): string {
    return this.$moment(
      `${this.currentYear} ${this.currentMonth}`,
      'YYYY M'
    ).format('MMMM')
  }

  get currentActiveNextMonth(): string {
    return this.$moment(
      `${this.currentYear} ${this.nextMonth}`,
      'YYYY M'
    ).format('MMMM')
  }

  get nextActiveMonth(): string {
    return this.$moment(`${this.currentYear} ${this.currentMonth}`, 'YYYY M')
      .add(1, 'month')
      .format('MMMM')
  }

  get nextActiveYear(): string {
    return this.$moment(`${this.currentYear} ${this.currentMonth}`, 'YYYY M')
      .add(1, 'month')
      .format('YYYY')
  }

  get showSelection(): string {
    if (this.singleDatePicker) {
      return this.dateIsApplied
        ? this.$moment(this.dateRangeSelection).format(this.displayFormat)
        : this.placeholder
    } else {
      const { from, to } = this.dateRangeSelection
      const fromDate = this.$moment(from).format(this.displayFormat)
      const endDate = this.dateRangeSelection.to
        ? this.$moment(to).format(this.displayFormat)
        : ''

      if (from) {
        if (fromDate === endDate || endDate === '') {
          return `${fromDate}`
        } else {
          // return `${fromDate} <span class="separator">${this.dateSeparator}</span> ${endDate}`
          return this.formatDateRange(fromDate, endDate)
        }
      } else {
        return ''
      }
    }
  }

  formatDateRange(fromDate: string, endDate: string): string {
    const from = {
      day: this.$moment(fromDate).date(),
      month: this.$moment(fromDate).month(),
      year: this.$moment(fromDate).year(),
    }
    const end = {
      day: this.$moment(endDate).date(),
      month: this.$moment(endDate).month(),
      year: this.$moment(endDate).year(),
    }

    if (from.year === end.year && from.month === end.month) {
      // check if start and end months and years are the same. If the are, group them and show days
      // eg. 1/1/2023 - 2/1/2023 returns "1 - 2 Feb 23"
      return `
      ${this.$moment(fromDate).format('D')}
      <span class="separator">${this.dateSeparator}</span>
      ${this.$moment(endDate).format('D MMM YY')}`
    } else if (from.year === end.year) {
      // check if just start and end years are the same. If the are, group them and show days
      // eg. 1/1/2023 - 2/2/2023 returns "1 Jan - 2 Feb 23"
      return `
      ${this.$moment(fromDate).format('D MMM')}
      <span class="separator">${this.dateSeparator}</span>
      ${this.$moment(endDate).format('D MMM YY')}`
    } else {
      // if days, months and years of start and end dates are different, show the two different dates in full
      // eg. 1/1/2023 - 2/2/2024 returns "1 Jan 23 - 2 Feb 24"
      return `
      ${this.$moment(fromDate).format('D MMM YY')}
      <span class="separator">${this.dateSeparator}</span>
      ${this.$moment(endDate).format('D MMM YY')}`
    }
  }

  get showAppliedSelection(): string {
    return this.dateIsApplied ? this.showSelection : this.placeholder
  }

  get triggerSpanClass(): string {
    return this.showAppliedSelection === this.placeholder
      ? 'datepicker__placeholder'
      : 'datepicker__selection'
  }

  get disableApplyButton(): boolean {
    return !this.dateRangeSelection.to
  }

  get disablePrevMonthButton(): boolean {
    if (this.minDate) {
      const minMonth = this.$moment(this.minDate, this.format)
      const thisMonth = this.$moment(
        `${this.currentYear} ${this.currentMonth}`,
        'YYYY M'
      )

      return thisMonth.isSameOrBefore(minMonth)
    }

    if (this.disablePastDates) {
      return true
    }

    return false
  }

  get disableNextMonthButton(): boolean {
    if (this.maxDate) {
      const maxMonth = this.$moment(this.maxDate, this.format)

      const thisMonth = this.$moment(
        `${this.currentYear} ${this.currentMonth}`,
        'YYYY M'
      )

      return thisMonth.isSameOrAfter(maxMonth)
    }

    if (this.disableFutureDates) {
      return true
    }

    return false
  }

  get presetIsValid(): boolean {
    return Boolean(
      this.presetRangeSelection &&
        this.presetRangeSelection.from &&
        this.presetRangeSelection.to
    )
  }

  hoverDateRange(range: Object) {
    this.hoverDateRangeSelection = range
  }

  addButtonClass(button: string): string {
    if (button === 'apply') {
      return [this.buttonClass, this.applyButtonClass].join(' ')
    } else {
      return [this.buttonClass, this.cancelButtonClass].join(' ')
    }
  }

  toggleIsOpen(): void {
    this.hoverDateRangeSelection = {}
    this.isOpen = !this.isOpen
    this.$emit(this.isOpen ? 'opened' : 'closed')
  }

  open(): void {
    this.isOpen = true
    this.$emit('opened')
  }

  close(): void {
    this.isOpen = false
    this.$emit('closed')
  }

  clear(): void {
    this.dateRangeSelection = {}
    this.dateIsApplied = false
    this.activePresetName = ''
  }

  cancelDateRange(): void {
    this.currentMonth = parseInt(this.$moment().format('M'))
    this.nextMonth = parseInt(this.$moment().add(1, 'month').format('M'))
    this.close()
    this.hoverDateRangeSelection = {}

    if (!this.dateIsApplied) {
      this.clear()
    }
  }

  setMonths(): void {
    let from
    if (this.dateIsApplied) {
      from = this.$moment(this.dateRangeSelection.from)
    } else {
      from = this.$moment()
    }
    this.currentMonth = parseInt(this.$moment(from).format('M'))
    this.currentYear = parseInt(this.$moment(from).format('YYYY'))
    this.nextMonth = parseInt(this.$moment(from).add(1, 'month').format('M'))
    this.nextYear = parseInt(this.$moment(from).add(1, 'month').format('YYYY'))
  }

  applyDates(): void {
    this.dateIsApplied = true

    this.setMonths()
    this.hoverDateRangeSelection = {}

    this.$emit('applyDateRange', this.dateRangeSelection)
  }

  selectRange(range?: DateRange): void {
    this.hoverDateRangeSelection = {}
    if (range) {
      this.dateRangeSelection = { from: range.from, to: range.to }
      this.activePresetName = range.label
    } else {
      this.clear()
    }

    if (this.autoApply) {
      this.applyDates()
    }
  }

  customDateRange(date: Date): void {
    this.hoverDateRangeSelection = {}

    this.activePresetName = ''
    if (this.singleDatePicker) {
      this.dateRangeSelection = date
      this.applyDates()
    } else {
      if (!this.dateRangeSelection.from || this.dateRangeSelection.to) {
        this.dateRangeSelection = { from: date, to: null }
      } else if (
        this.dateRangeSelection.from &&
        this.$moment(date).isBefore(this.$moment(this.dateRangeSelection.from))
      ) {
        this.dateRangeSelection = { from: date, to: null }
      } else {
        this.dateRangeSelection = {
          from: this.dateRangeSelection.from,
          to: date,
        }
      }

      if (this.autoApply && this.dateRangeSelection.to) {
        this.applyDates()
      }
    }
  }

  getDateString(date: Date): null | Date {
    if (!date) {
      return null
    } else {
      return date
    }
  }

  changeMonth(direction: string): void {
    this.hoverDateRangeSelection = {}
    let newMonth
    if (direction === 'forward') {
      newMonth = this.$moment(
        `${this.currentMonth} ${this.currentYear}`,
        'M YYYY'
      ).add(1, 'month')
      this.currentMonth = parseInt(newMonth.format('M'))
      this.currentYear = parseInt(newMonth.format('YYYY'))
    } else if (direction === 'backward') {
      newMonth = this.$moment(
        `${this.currentMonth} ${this.currentYear}`,
        'M YYYY'
      ).subtract(1, 'month')

      this.currentMonth = parseInt(newMonth.format('M'))

      this.currentYear = parseInt(newMonth.format('YYYY'))
    }

    newMonth.add(1, 'month')

    this.nextMonth = parseInt(newMonth.format('M'))

    this.nextYear = parseInt(newMonth.format('YYYY'))
  }

  selectMonth(date: Object): void {
    const from = this.$moment(`${date.year} ${date.month}`, 'YYYY M') // new Date(Date.UTC(date.year, date.month, 1))
    const to = this.$moment(`${date.year} ${date.month}`, 'YYYY M').add(
      '1',
      'month'
    ) // new Date(Date.UTC(date.year, date.month + 1, 0))
    const today = this.$moment()

    const minDate = this.allowPastDates ? this.isMinDate : today
    const maxDate = this.allowFutureDates ? this.isMaxDate : today

    this.hoverDateRangeSelection = {}

    if (this.minDate || !this.allowPastDates) {
      if (minDate!.getTime() > from.getTime()) {
        this.dateRangeSelection = { from: minDate }
      } else {
        this.dateRangeSelection = { from, to }
      }
    } else {
      this.dateRangeSelection = { from, to }
    }

    if (this.maxDate || !this.allowFutureDates) {
      if (maxDate!.getTime() < to.getTime()) {
        this.dateRangeSelection.to = maxDate
      } else {
        this.dateRangeSelection.to = to
      }
    } else {
      this.dateRangeSelection.to = to
    }

    if (this.autoApply) {
      this.applyDates()
    }
  }

  handleClickOutsideComponent(event): void {
    this.hoverDateRangeSelection = {}

    if (
      this.$refs.datepicker &&
      !this.$refs.datepicker.contains(event.target)
    ) {
      this.close()
    }
  }

  mounted(): void {
    document.addEventListener('click', this.handleClickOutsideComponent)
    if (this.presetRangeSelection && this.presetIsValid) {
      this.dateRangeSelection = this.presetRangeSelection
      this.applyDates()
    }
  }

  beforeDestory(): void {
    this.hoverDateRangeSelection = {}
    document.removeEventListener('click', this.handleClickOutsideComponent)
  }

  @Emit()
  applyDateRange() {
    this.dateIsApplied = true
    this.close()
    return this.dateRangeSelection
  }
}

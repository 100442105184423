













































































import { Component, Vue, Prop, VModel } from 'nuxt-property-decorator'
// import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

@Component
export default class RangeField extends Vue {
  @Prop({ type: String, required: true }) label!: string
  @Prop({ type: Number, required: true }) min!: number
  @Prop({ type: Number, required: true }) max!: number

  @VModel() range

  private dragging: Boolean = false
  private offset: number | null = null
  private buttons: string[] = ['minThumb', 'maxThumb']

  private percentageDisplaced: Object = {
    minThumb: 0,
    maxThumb: 1,
  }
  private activeThumb: string | null = null

  get hideMinValue(): boolean {
    return this.percentageDisplaced.minThumb > 0 && !this.combineValues
  }

  get hideMaxValue(): boolean {
    return this.percentageDisplaced.maxThumb < 1 && !this.combineValues
  }

  get combineValues(): boolean {
    return (
      this.percentageDisplaced.maxThumb - this.percentageDisplaced.minThumb <
      0.2
    )
  }

  get roundBy(): number {
    if (this.range.max < 100) {
      return 5
    } else if (this.range.max < 2000) {
      return 10
    } else {
      return 100
    }
  }

  get width(): number {
    return this.$refs.slider.offsetWidth
  }

  startingPositions() {
    this.buttons.forEach((ref) => {
      if (this.$refs[ref]) {
        this.$refs[ref].style.left = `${
          this.width * this.percentageDisplaced[ref]
        }px`
      }
    })
  }

  getPosition(event: Event): number {
    if (event.type === 'touchmove') {
      return event.touches[0].screenX - this.offset!
    } else {
      return event.clientX - this.offset!
    }
  }

  moveThumb(x: number): void {
    if (this.activeThumb) {
      if (
        x >= 0 &&
        x <= this.width &&
        this.percentageDisplaced.maxThumb >=
          this.percentageDisplaced.minThumb &&
        this.percentageDisplaced.minThumb <= this.percentageDisplaced.maxThumb
      ) {
        this.$refs[this.activeThumb].style.left = `${x}px`
      }
    }
  }

  dragStart(button: string): void {
    this.dragging = true
    this.activeThumb = button
    // disableBodyScroll(this.$refs.range)
  }

  dragStop(): void {
    this.dragging = false
    this.activeThumb = null
    // clearAllBodyScrollLocks()
  }

  applyProgressBarStyles(): void {
    const width =
      this.width *
      (this.percentageDisplaced.maxThumb - this.percentageDisplaced.minThumb)

    const left = this.width * this.percentageDisplaced.minThumb

    this.$refs.progress.style.width = `${width}px`
    this.$refs.progress.style.left = `${left}px`
  }

  applyCombinedValuesStyles(): void {
    const centreOfProgressBar =
      (this.percentageDisplaced.maxThumb - this.percentageDisplaced.minThumb) /
      2

    const left =
      this.width * (this.percentageDisplaced.minThumb + centreOfProgressBar)

    this.$refs.combineValues.style.left = `${left}px`
  }

  findThumbDisplacement(position: number): void {
    const displaced = position / this.width

    if (this.activeThumb) {
      const newVal = this.roundValue(
        (this.max - this.min) * this.percentageDisplaced[this.activeThumb]
      )

      if (displaced >= 0 && displaced <= 1) {
        this.percentageDisplaced[this.activeThumb] = displaced

        if (this.activeThumb === 'minThumb' && newVal <= this.range.max) {
          this.range.min = newVal
        } else if (
          this.activeThumb === 'maxThumb' &&
          newVal >= this.range.min
        ) {
          this.range.max = newVal
        }

        this.applyProgressBarStyles()

        if (this.range.min !== this.range.max) {
          this.applyCombinedValuesStyles()
        }
      } else if (displaced < 0) {
        this.percentageDisplaced[this.activeThumb] = 0
      } else {
        this.percentageDisplaced[this.activeThumb] = 1
      }
    }
  }

  drag(event: Event): void {
    if (this.dragging) {
      const position = this.getPosition(event)

      this.findThumbDisplacement(position)
      this.moveThumb(position)
      //     this.updateValue()
    }
  }

  roundValue(value: number): number {
    const roundBy = this.roundBy
    const roundedValue = Math.round(value / roundBy) * roundBy

    if (roundedValue > this.max) {
      return this.max
    } else if (roundedValue < this.min) {
      return this.min
    } else return roundedValue
  }

  // updateValue(): void {
  //   this.val = this.roundValue()
  //   this.$emit('val', this.val)
  // }

  init(): void {
    this.offset = this.$refs.slider
      ? this.$refs.slider.getBoundingClientRect().left
      : 0
    this.startingPositions()

    document.addEventListener('mousemove', this.drag)
    document.addEventListener('mouseup', this.dragStop)
    document.addEventListener('touchmove', this.drag)
    document.addEventListener('touchend', this.dragStop)
  }

  mounted(): void {
    this.$nextTick(() => {
      setTimeout(() => {
        // add this setTimeout because transitions break the init function's starting position calculations.
        this.init()
      }, 1000)
    })
  }

  beforeDestroy(): void {
    document.removeEventListener('mousemove', this.drag)
    document.removeEventListener('mouseup', this.dragStop)
    document.removeEventListener('touchmove', this.drag)
    document.removeEventListener('touchend', this.dragStop)
  }
}

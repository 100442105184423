



































import { Component, Vue, Emit, Inject } from 'nuxt-property-decorator'
import Month from '@UI/components/date-picker/Month'
import { DateRange } from './defaultPresets'

@Component({
  components: {
    Month,
  },
})
export default class MobileCalendar extends Vue {
  @Inject() readonly isMinDate!: Date | null
  @Inject() readonly isMaxDate!: Date | null
  @Inject() readonly isSingleDate!: Boolean

  private currentMonth: number = parseInt(this.$moment().format('M'))
  private pastMonthsCount: number = this.currentMonth - 2
  private currentYear: number = parseInt(this.$moment().format('YYYY'))
  private futureMonthsCount: number = this.currentMonth + 3
  private containerHeight: number = 0

  get monthsBefore(): Object[] {
    const monthsDiff = this.isMinDate
      ? this.isMinDate?.getMonth() -
        this.currentMonth +
        12 * (this.isMinDate?.getFullYear() - this.currentYear)
      : null
    let arr: Object[] = []

    const calculateMonth = (index: number) => {
      let year: number
      let month: number

      if (index >= 0) {
        month = this.$moment(
          `${this.currentYear}-${index}-${1}`,
          'YYYY-M-D'
        ).format('M')
        year = this.currentYear
      } else {
        month = this.$moment(
          `${this.currentYear}-${12 + (index % 12)}-1`,
          'YYYY-M-D'
        ).format('M')
        year = this.$moment(
          `${this.currentYear}-${index}-1`,
          'YYYY-M-D'
        ).format('YYYY')
      }

      return { month, year }
    }

    for (let i = this.pastMonthsCount; i < this.currentMonth; i++) {
      if (monthsDiff !== null) {
        if (monthsDiff !== 0 && i > monthsDiff + 2) {
          arr.push(calculateMonth(i))
        }
      } else {
        arr.push(calculateMonth(i))
      }
    }

    return arr
  }

  get monthsAfter(): Object[] {
    const monthsDiff = this.isMaxDate
      ? this.isMaxDate?.getMonth() -
        this.currentMonth +
        12 * (this.isMaxDate?.getFullYear() - this.currentYear)
      : null
    let arr: Object[] = []

    const calculateMonth = (index: number) => {
      let month: number
      let year: number

      if (index > 11) {
        month = this.$moment(
          `${this.currentYear}-${12 + (index % 12)}-1`,
          'YYYY-M-D'
        ).format('M')
        year = this.$moment(
          `${this.currentYear}-${index}-1`,
          'YYYY-M-D'
        ).format('YYYY')
      } else {
        month = index
        year = this.currentYear
      }

      return { month, year }
    }

    for (let i = this.currentMonth + 1; i < this.futureMonthsCount; i++) {
      if (monthsDiff !== null) {
        if (monthsDiff === 0 && i < monthsDiff + this.currentMonth + 2) {
          arr.push(calculateMonth(i))
        } else if (monthsDiff < 3 && i < monthsDiff + this.currentMonth + 1) {
          arr.push(calculateMonth(i))
        } else if (i <= monthsDiff + this.currentMonth) {
          arr.push(calculateMonth(i))
        }
      } else {
        arr.push(calculateMonth(i))
      }
    }

    return arr
  }

  get monthHeight(): number {
    return this.containerHeight / 2
  }

  monthButtonClass(month: number, year: number): Boolean | string {
    const maxMonth = new Date(
      Date.UTC(this.isMaxDate!.getFullYear(), this.isMaxDate!.getMonth(), 1)
    ).getTime()
    const thisMonth = new Date(Date.UTC(year, month, 1)).getTime()

    return maxMonth < thisMonth && 'calendar-month__month-button--disabled'
  }

  handleScroll() {
    const scrollHeight = this.$refs.calendar
      ? this.$refs.calendar.scrollTop
      : 300

    if (scrollHeight < this.monthHeight) {
      this.pastMonthsCount--
    } else if (
      scrollHeight >
      this.$refs.calendarWrapper.offsetHeight - 2 * this.monthHeight
    ) {
      this.futureMonthsCount++
    }
  }

  scrollToCurrentMonth() {
    this.$refs.calendar.scrollTop = this.$refs.currentMonth.$el.offsetTop
  }

  init(): void {
    this.containerHeight = this.$refs.calendar.clientHeight
    this.scrollToCurrentMonth()
  }

  mounted(): void {
    this.$nextTick(() => this.init())
  }

  beforeDestroy(): void {
    this.pastMonthsCount = this.currentMonth - 2
    this.futureMonthsCount = this.currentMonth + 3
  }

  @Emit('selectDate')
  selectDate(range?: DateRange) {
    return range
  }

  @Emit('selectMonth')
  selectMonth(date: Object) {
    return date
  }
}



















import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class MobileRangeField extends Vue {
  @Prop({ type: String }) label?: string
  @Prop({ type: Date }) date?: string

  get formattedDate(): string {
    if (this.date) {
      return this.$moment(this.date).format('YYYY-MM-DD')
    } else {
      return ''
    }
  }

  set formattedDate(value) {
    this.$emit('input', this.$moment(value).toDate())
  }

  handleDateChange($event: Event) {
    this.formattedDate = $event.target.value
  }

  onBlur(event) {
    this.$emit('date-changed')
  }
}

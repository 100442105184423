































import { Component, Emit, VModel, Vue } from 'nuxt-property-decorator'
import CancelIcon from '~/static/images/icons/cancel.svg?inline'

@Component({
  components: {
    CancelIcon,
  },
})
export default class SearchEvents extends Vue {
  @VModel({ type: String }) searchTerm!: string

  get message(): string | false {
    const plural = this.searchTerm.length === 1 ? 's' : ''
    return this.searchTerm.length < 3 && this.searchTerm.length > 0
      ? `Please type ${3 - this.searchTerm.length} more character${plural}`
      : false
  }

  @Emit()
  clearSearchTerm() {
    return 'clearSearchTerm'
  }
}

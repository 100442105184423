
































import { Component, Prop, VModel, Vue } from 'nuxt-property-decorator'
import { ValidationProvider } from 'vee-validate'
import CheckboxField from '@UI/components/forms/fields/CheckboxField.vue'

@Component({
  components: {
    ValidationProvider,
    CheckboxField,
  },
})
export default class CheckboxGroup extends Vue {
  @Prop({ type: Array, required: true }) choices!: Array<{
    label: string
    sublabel: string
    value: number
    disabled: Boolean
  }>
  @Prop({ type: String, default: () => '' }) label?: string
  @Prop({ type: String }) sublabel?: string
  @Prop({ type: Boolean, required: false, default: false }) required?: Boolean
  @Prop({ type: Boolean, required: false, default: false })
  labelUppercase?: Boolean

  @Prop({ type: Boolean, required: false, default: () => false })
  inline?: Boolean
  @Prop({ type: Boolean, required: false, default: () => false })
  boxed?: Boolean

  @VModel({ type: Array }) selection!: string[]

  get isRequired() {
    return this.required ? { required: { allowFalse: false } } : ''
  }
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes loading{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}.toggle-view{display:grid;align-items:center;justify-content:space-between;column-gap:.5rem;row-gap:1.5rem;grid-template-columns:1fr max-content;grid-template-rows:auto auto;grid-template-areas:\"search toggle\" \"filters filters\"}@media only screen and (min-width:1024px){.toggle-view{grid-template-columns:1fr max-content max-content;grid-template-areas:\"filters search toggle\";column-gap:1.5rem}}.toggle-view__button-wrapper{display:flex;align-items:center;flex:0 0 auto;grid-area:toggle}.toggle-view__content{margin-top:1rem}@media only screen and (min-width:1024px){.toggle-view__content{margin-top:2rem}}.toggle-view__button{padding:1rem;color:rgba(247,246,243,.6);display:inline-flex;gap:.25rem;align-items:center;background:rgba(71,71,71,.6);transition:opacity .2s ease-in-out,background .2s ease-in-out;font-weight:600;line-height:1.1875rem;margin:0}.toggle-view__button svg{fill:rgba(247,246,243,.6);transition:fill .2s ease-in-out}.toggle-view__button:hover{background:#616161}.toggle-view__button--grid{border-top-left-radius:6.25rem;border-bottom-left-radius:6.25rem}.toggle-view__button--list{border-top-right-radius:6.25rem;border-bottom-right-radius:6.25rem}.toggle-view__button--active{background:#474747;color:#f7f6f3}.toggle-view__button--active svg{fill:#f7f6f3}.toggle-view__button:focus{z-index:2}.toggle-view__filters{flex:1 0 50%;grid-area:filters}.toggle-view .search-events{justify-self:flex-end;flex:0 0 auto;grid-area:search;width:100%}@media only screen and (min-width:1024px){.toggle-view .search-events{width:unset}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black": "#000",
	"blackBg": "#121212",
	"white": "#fff",
	"offWhite": "#f7f6f3",
	"grey": "#b7b7b8",
	"darkGrey": "#474747",
	"mediumGrey": "#7d7d7d",
	"lightGrey": "#e0e0e0",
	"darkGrey60": "rgba(66,66,66,.6)",
	"lightGrey60": "hsla(0,0%,87.8%,.6)",
	"red": "#db0000",
	"violet": "#b8a4ea",
	"lime": "#d4fe01",
	"price1": "#e6ff66",
	"price2": "#b699ff",
	"price3": "#66ff91",
	"price4": "#f68383",
	"price5": "#80a4ff",
	"price6": "#ff99c0",
	"price7": "#00b893",
	"price8": "#ff9166",
	"screen375": "375px",
	"screen480": "480px",
	"screen640": "640px",
	"screen768": "768px",
	"screen1024": "1024px",
	"screen1200": "1200px",
	"screen1440": "1440px",
	"screen1560": "1560px"
};
module.exports = ___CSS_LOADER_EXPORT___;




























































import { Vue, Component, Prop, namespace } from 'nuxt-property-decorator'
import {
  Availability,
  Event,
  Instance,
  BasketProperties,
  ZonePrice,
} from '@Core/@types/skyway'
import { singleDateFormatter, timeFormatter } from '@Core/helpers/dateFormatter'
import {
  validateModeOfSale,
  hasOpenModeOfSale,
  hasForthcomingModeOfSale,
  InstanceStates,
  parseEventState,
  openModesOfSale,
} from '@Tickets/helpers/mode-of-sale'
import { pricesToExclude } from '@Tickets/helpers/screens'
import {
  fullPriceRange,
  formatPriceRange,
  rangeFromInstance,
} from '@Events/helpers/priceFormatter'
import TextTag from '@UI/components/tags/TextTag.vue'
import ArrowRightSvg from '~/static/images/icons/arrow.svg?inline'
import InfoSvg from '~/static/images/icons/info.svg?inline'

const basket = namespace('basket')

@Component({
  components: {
    TextTag,
    ArrowRightSvg,
    InfoSvg,
  },
})
export default class BookingButton extends Vue {
  @Prop({ type: Object, required: true }) instance!: Instance
  @Prop({ type: Object, required: true }) event!: Event
  @Prop({ type: String, required: true }) type!: string

  @basket.State
  protected properties!: BasketProperties

  @basket.Action
  protected getBasketProperties!: () => Promise<BasketProperties>

  // Only these tags are to be displayed on each instance
  protected tagsToShow = [
    'online',
    'subtitled',
    'signed',
    'preview',
    'matinee',
    'relaxed',
    'autism-friendly',
    'press-night',
    'audio-described',
  ]

  /**
   * Exclude prices with these names from lowest
   * price calculation
   */
  protected pricesToExclude = pricesToExclude

  get availability(): Availability[] {
    return this.instance.availability as Availability[]
  }

  get limitedAvailability(): number {
    return (
      (this.instance.event && this.instance.event.limited_availability) || 0
    )
  }

  get totalAvailability(): number {
    return (
      this.availability &&
      this.availability.reduce((acc, cur) => {
        return acc + (cur.availability as number) || 0
      }, 0)
    )
  }

  get formattedDate(): string {
    return singleDateFormatter(
      this.$moment(this.instance.date_time),
      'lg',
      true
    )
  }

  get formattedTime(): string {
    return timeFormatter(this.$moment(this.instance.date_time))
  }

  get tags(): string[] | null {
    if (this.instance.tags) {
      const instanceTags = this.instance.tags.map((tag) => {
        const tagLowercase =
          tag && tag.tag ? tag.tag.replace(/\s+/g, '-').toLowerCase() : ''
        return tag && tag.tag && this.tagsToShow.includes(tagLowercase)
          ? tag.tag
          : ''
      })
      return instanceTags.filter((n) => n && String(n).length > 0)
    } else {
      return null
    }
  }

  get bookingURL(): string {
    if (this.hasExternalBookingLink) {
      return String(this.event.external_booking)
    }
    switch (this.status) {
      case InstanceStates.PreSale:
      case InstanceStates.Priority:
      case InstanceStates.Members:
      case InstanceStates.AdvancePriority:
        return this.$config.get('URLS').memberships
      case InstanceStates.OnSale:
        return `${this.$config.get('URLS').select_area}${
          this.instance.instance_ref
        }`
      default:
        return ''
    }
  }

  get hasExternalBookingLink() {
    return Boolean(
      this.event.external_booking &&
        typeof this.event.external_booking === 'string' &&
        this.event.external_booking.indexOf('http') === 0
    )
  }

  get linkComponent() {
    return this.hasExternalBookingLink ? 'a' : 'NuxtLink'
  }

  getPriceAvailability(zonePrice: ZonePrice): number {
    const availability = this.availability?.find(
      (av) => av.zone_ref === zonePrice.zone_ref
    )

    return availability?.availability || 0
  }

  get priceRange(): string {
    return formatPriceRange(
      rangeFromInstance(this.instance),
      this.$formatCurrency
    )
  }

  get priceFrom(): string | null {
    if (this.instance && this.instance.prices) {
      const isFree = this.instance.prices[0]?.price_title === 'Free'

      if (isFree) {
        return 'Free'
      } else {
        const prices: number[] =
          this.instance.prices &&
          this.instance.prices.reduce((acc: number[], current) => {
            if (current && current.price) {
              if (
                current.price > 0 &&
                this.getPriceAvailability(current) > 0 &&
                current.price_title &&
                !this.pricesToExclude.includes(
                  current.price_title.toLowerCase()
                )
              ) {
                acc.push(current.price)
              }
            }
            return acc
          }, [])

        const minPrice = prices.length ? Math.min(...prices) : 0

        return minPrice > 0 ? `From ${this.$formatCurrency(minPrice)}` : ''
      }
    } else {
      return null
    }
  }

  get hasPast(): boolean {
    const instanceDate = this.$moment(this.instance.date_time)

    if (instanceDate.isBefore(this.$moment())) {
      return true
    } else {
      return false
    }
  }

  get status(): InstanceStates {
    if (!this.properties || !this.properties.mode_of_sale_ref) {
      return InstanceStates.OffSale
    }
    if (this.event && this.event.on_sale === false) {
      return InstanceStates.OffSale
    }

    const soldOutstate = this.isSoldOut()

    if (this.hasPast) {
      return InstanceStates.PastInstance
    } else if (
      validateModeOfSale(
        this.instance,
        this.properties.mode_of_sale_ref,
        this.$moment
      )
    ) {
      if (soldOutstate) {
        return soldOutstate
      }

      return InstanceStates.OnSale
    } else if (hasOpenModeOfSale(this.instance, this.$moment)) {
      return parseEventState(openModesOfSale(this.instance, this.$moment))
    } else if (hasForthcomingModeOfSale(this.instance, this.$moment)) {
      return InstanceStates.ComingSoon
    } else {
      return InstanceStates.OffSale
    }
  }

  isSoldOut() {
    if (this.limitedAvailability) {
      return InstanceStates.LowAvailability
    } else if (
      this.totalAvailability === 0 ||
      this.totalAvailability <= Number(this.instance.wheelchair_availability)
    ) {
      return InstanceStates.SoldOut
    }
    return false
  }

  get importantInformation(): string | null {
    return this.instance.extra && this.instance.extra.important_info
      ? this.instance.extra.important_info
      : null
  }

  get buttonText(): string {
    switch (this.status) {
      case InstanceStates.SoldOut:
        return 'Sold out'
      case InstanceStates.OnSale:
        return 'Book'
      case InstanceStates.Priority:
        return 'Priority'
      case InstanceStates.AdvancePriority:
        return 'Advanced priority'
      case InstanceStates.Members:
      case InstanceStates.ComingSoon:
      case InstanceStates.PreSale:
        return 'On sale soon'
      case InstanceStates.LowAvailability:
        return 'Last few tickets'
      case InstanceStates.PastInstance:
        return 'Past event'
      default:
        return 'Not on sale'
    }
  }

  get isDisabled(): boolean {
    return this.status !== InstanceStates.OnSale
  }

  get isLowAvailability(): boolean {
    if (this.totalAvailability && this.totalAvailability > 0) {
      return this.limitedAvailability >= this.totalAvailability
    }

    return false
  }

  async mounted() {
    if (!this.properties) {
      await this.getBasketProperties()
    }
  }
}

import { Instance, ZonePrice } from '@Core/@types/skyway'

type Range = {
  min: number
  max: number
}

export const formatPriceRange = (
  price_range: Range,
  formatter: (amount: number) => string
): string => {
  if (price_range.min === price_range.max) {
    return formatter(price_range.min)
  } else {
    return `${formatter(price_range.min)} - ${formatter(price_range.max)}`
  }
}

export const rangeFromInstance = (
  instance: Instance
): { max: number; min: number } => {
  const prices = instance?.prices || []

  const result = prices.reduce(
    (acc, current) => {
      if (current.enabled && current.price < acc.min) {
        acc.min = current.price
      }
      if (current.enabled && current.price > acc.max) {
        acc.max = current.price
      }
      return acc
    },
    { min: Infinity, max: -Infinity }
  )
  if (result.min === Infinity || result.max === -Infinity) {
    return { min: 0, max: 0 }
  }

  return result
}





















import { Component, Prop, Vue, Watch, namespace } from 'nuxt-property-decorator'
import { Event, DateRangeInput, Series, Instance } from '@Core/@types/skyway'
import cloneDeep from 'lodash/cloneDeep'

const basket = namespace('basket')
const events = namespace('events')
const instances = namespace('instances')

@Component({
  components: {
    Error: () => import('@Core/components/errors/Error.vue'),
  },
})
export default class EventsContainer extends Vue {
  @Prop({ type: String, default: 'getEvents' }) eventsQuery!: string
  @Prop({ type: Number }) seriesId!: number
  @Prop({ type: Boolean, default: false }) showPrivate!: false
  @Prop({ type: String, required: true }) view!: string
  @Prop({ type: String }) currentMonth?: string
  @Prop({ type: Boolean, default: false }) isSeriesPage!: boolean

  public loading = false

  @events.State
  public events

  @events.Mutation('RESET_EVENT')
  protected resetEvent!: () => void

  @events.Action
  public getSeries!: () => Promise<Series[]>

  @events.Action
  public getEventsByDate!: (range: DateRangeInput) => Promise<Event[]>

  @events.Mutation('ADD_SERIES_TO_EVENTS')
  public addSeriesToEvents!: (series: any[]) => void

  @instances.State
  public filteredInstances!: Instance[] | []

  @instances.Getter
  instancesByDay

  @instances.Action
  protected getInstancesByDateRange

  @instances.Action
  protected getSeriesInstancesByDateRange!: (params: {
    alias: string
    range: DateRangeInput
    month: string | undefined
  }) => Promise<Instance[]>

  @instances.Action
  protected filterInstances

  @basket.Action
  getBasketProperties

  @basket.State
  properties

  get title(): string {
    switch (this.$route.name) {
      case 'events-archive':
        return 'Programme Archive'
      default:
        return 'Programme'
    }
  }

  get isArchive() {
    return this.$route.name === 'events-archive'
  }

  /**
   * See https://app.asana.com/0/1205657949822118/1205726611990535
   *
   * SM: I've had to add a hack to remove the Kagami event cards from the whats on
   * grid and just show the series card
   */
  get filteredEvents(): Event[] {
    const archive = cloneDeep(this.events)
    switch (this.$route.name) {
      case 'events-archive':
        return archive
          .filter((a) => this.$moment(a.last_date) < this.$moment())
          .reverse()
      default:
        return this.showPrivate
          ? this.events
          : this.events.filter(
              (e) =>
                !e.private &&
                (e.type !== 'event' ||
                  !e.title?.toLowerCase().includes('kagami'))
            )
    }
  }

  get seriesEvents() {
    const seriesId = this.seriesId
    const events = this.events

    const filteredEvents =
      events && events.length
        ? this.events.filter((event) => {
            if (event.series_id === seriesId) {
              return event
            }
          })
        : []
    return filteredEvents
  }

  /**
   * Instances need to be grouped by day to fit the UI design.
   *
   * this.filteredInstances will return all instances if there are no filters applied.
   */
  get instances() {
    if (
      this.view === 'EventsCalendarList' &&
      this.filteredInstances &&
      !this.loading
    ) {
      return this.instancesByDay(this.currentMonth, this.$moment)
    } else {
      return null
    }
  }

  /**
   * Watch both the view and the current month. If either changes, set loading to true
   * while it fetches instances by date range. The instances property in the store's state
   * is mutated with the fetched instances.
   *
   * The 'from' date will be the start of the month if this.currentMonth is a future month,
   * or today's date if this.currentMonth is the present month.
   *
   * Dates need to be in UTC format, and an ISO string.
   *
   * When instances return, apply any filters which have already been selected.
   *
   * Set loading to false.
   */
  @Watch('view')
  @Watch('currentMonth')
  async onViewChange() {
    this.loading = true
    const todaysMonth = this.$moment().format('YYYY-MM')
    let from: string

    if (todaysMonth === this.currentMonth) {
      from = this.$moment.utc().toISOString()
    } else {
      from = this.$moment.utc(this.currentMonth).startOf('month').toISOString()
    }
    const range = {
      from,
      to: this.$moment
        .utc(this.currentMonth)
        .endOf('month')
        .add(1, 'day')
        .toISOString(),
    }

    // if the page isn't a series landing page (https://www.roundhouse.org.uk/series/*)
    // fetch instances by date range
    if (!this.isSeriesPage) {
      await this.getInstancesByDateRange({
        range,
        brief: true,
        type: 'event,class',
        month: this.currentMonth,
      })
    } else {
      // if the page is a series landing page fetch instances in that series by date range
      await this.getSeriesInstancesByDateRange({
        alias: this.$route.params.id,
        range,
        month: this.currentMonth,
      })
    }

    this.filterInstances()

    this.loading = false
  }

  scrollToFilters() {
    const { query } = this.$route
    this.$nextTick(() => {
      const filters = document.getElementsByClassName('events__header')
      if (Object.keys(query).length) {
        setTimeout(() => {
          filters[0].scrollIntoView()
        }, 400)
      }
    })
  }

  @Watch('$fetchState.pending')
  realignScroll(newVal) {
    if (newVal === false) {
      this.scrollToFilters()
    }
  }

  async mounted() {
    if (!this.properties) {
      await this.getBasketProperties()
    }

    if (!this.events || this.events.length < 1) {
      await this.getEvents()
    }

    const series = await this.getSeries()
    this.addSeriesToEvents(series)
    this.scrollToFilters()

    if (this.isSeriesPage) {
      await this.onViewChange()
    }
  }

  async getEvents() {
    if (this.isArchive) {
      const events = await this.getEventsByDate({
        from: this.$moment().subtract(1, 'month').format(),
        to: this.$moment().format(),
      })

      return events
    } else {
      const today = this.$moment().utc()
      const lastNight = today.subtract(1, 'day').set({
        hour: 23,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      const events = await this.getEventsByDate({
        from: lastNight.format(),
        to: this.$moment().utc().add(18, 'month').format(),
      })

      return events
    }
  }

  async fetch(): Promise<any> {
    this.resetEvent()
    if (!this.events || this.events.length < 1) {
      return await this.getEvents()
    }
  }
}























import { Component, Prop, Vue } from 'nuxt-property-decorator'
import DateCardDetails from '@Events/components/events/DateCardDetails.vue'
import BookingButton from '@Events/components/event/booking-buttons/BookingButton.vue'
import ArrowRightThickSvg from '@icons/material/svg/arrow-right-thick.svg?inline'
import Observer from '@UI/components/intersection-observer/IntersectionObserver.vue'
import { Instance } from '@Core/@types/skyway'

@Component({
  inheritAttrs: false,
  components: {
    ArrowRightThickSvg,
    DateCardDetails,
    BookingButton,
    Observer,
  },
})
export default class DateCard extends Vue {
  @Prop({ type: Array }) data!: Instance[]
  @Prop({ type: Boolean, default: false }) isSeriesPage!: boolean

  /**
   * When data comes into view, emit the event_ref to load
   * availability
   */
  onDateIntersect() {
    if (this.data[0] && !this.data[0].availability) {
      const { event_ref } = this.data[0].event
      if (event_ref) {
        this.$emit('load-availability', event_ref)
      }
    }
  }

  get date() {
    return (
      this.data &&
      this.data[0] &&
      this.$moment(this.data[0].date_time).format('ddd D')
    )
  }
}

// push/remove url param
const update = async (app, param: string, value: string[]) => {
  const query = Object.assign({}, app.$route.query)

  const escapedArray = value.map((i) => {
    if (param === 'dateTo' || param === 'dateFrom') {
      return i
    } else {
      return i.replace(' ', '_')
    }
  })

  if (!escapedArray.length) {
    delete query[param]
  } else {
    query[param] = encodeURIComponent(escapedArray.join(','))
  }

  await app.$router
    .replace({ query, path: app.$route.path })
    .catch((err) => err)
}

// parse url params
const parse = (params: string) => {
  const parsedCats = decodeURIComponent(params).split(',')
  return parsedCats.map((i) => i.replace('_', ' '))
}

const parseDate = (app, params) => {
  const parsedDate = decodeURIComponent(params)
  return app.$moment(parsedDate, 'yyyy_MM_DD').toDate()
}

const reset = (app) => {
  app.$router.replace({ path: app.$route.path }).catch((err) => err)
}

export default {
  update,
  parse,
  parseDate,
  reset,
}

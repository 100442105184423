































import { Component, Prop, VModel, Vue } from 'nuxt-property-decorator'
import MultiSelectDropdown from '@UI/components/forms/fields/multiselect/MultiSelectDropdown.vue'
import ArrowDropDownSvg from '~/static/images/icons/arrow-drop-down.svg?inline'

/**
 * UI container component to display a dropdown list of checkbox options
 */
@Component({
  components: {
    MultiSelectDropdown,
    CheckboxGroup: () =>
      import('@UI/components/forms/fields/CheckboxGroup.vue'),
    FiltersCheckboxGroup: () =>
      import('@UI/components/filters/FiltersCheckboxGroup.vue'),
    ArrowDropDownSvg,
  },
})
export default class FilterDropdownCheckboxes extends Vue {
  /** Array of checkbox options */
  @Prop({ type: Array, required: true }) options!: Array<any>

  /** Text label for the component */
  @Prop({ type: String }) label?: string
  /** Pass v-model from container */
  @Prop({ type: String || Number }) value?: string | number
  @Prop({
    type: String,
    default: 'CheckboxGroup',
    validator(value) {
      return ['CheckboxGroup', 'FiltersCheckboxGroup'].includes(value)
    },
  })
  component!: string

  @VModel({ type: Array, default: () => [] }) selection!: Array<any>
}

























import { Component, VModel, Vue } from 'nuxt-property-decorator'
import { DateRange } from '@UI/components/date-picker/defaultPresets'
import MobileRangeField from '@UI/components/date-picker/MobileRangeField.vue'

@Component({
  components: { MobileRangeField },
})
export default class MobileRangeFields extends Vue {
  @VModel({
    type: Object,
  })
  dateRange!: DateRange

  protected fromField: HTMLInputElement | null = null
  protected toField: HTMLInputElement | null = null
  protected error: string | null = null

  onFromDateChanged(): void {
    this.error = null
    if (this.toField) {
      this.toField.focus()
    }
  }

  onToDateChanged(): void {
    if (this.isValid) {
      this.error = null
      this.$emit('date-range-applied', this.dateRange)
    } else {
      this.error = 'Please check that you have selected a valid range.'
    }
  }

  get isValid(): boolean {
    return Boolean(
      this.$moment(this.dateRange.from, 'YYYY-MM-DD').isValid() &&
        this.$moment(this.dateRange.to, 'YYYY-MM-DD').isValid() &&
        this.$moment(this.dateRange.from, 'YYYY-MM-DD').isSameOrBefore(
          this.$moment(this.dateRange.to, 'YYYY-MM-DD')
        )
    )
  }

  async mounted(): Promise<void> {
    await this.$nextTick()

    this.fromField = this.$refs?.from?.$refs.field
    this.toField = this.$refs?.to?.$refs.field
  }
}

































































import {
  Component,
  Emit,
  Prop,
  Ref,
  Vue,
  namespace,
} from 'nuxt-property-decorator'
import ToggleView from '@UI/components/toggle-view/ToggleView.vue'
import EventsGrid from '@Events/components/events/EventsGrid.vue'
import EventsCalendarList from '@Events/components/events/EventsCalendarList.vue'
import CheckboxFilters from '@UI/components/filters/CheckboxFilters.vue'
import EventFilters from '@Events/components/events/EventFilters.vue'
import { Throttle } from '@Core/decorators'
import { ViewOptions } from '@Events/components/containers/SeriesContainer.vue'
import { DateRangeInput, Event, Instance } from '@Core/@types/skyway'
import { FilterModel } from '@Events/store/events/state'
import urlParams from '@UI/helpers/urlParams/urlParmas'
import { filterData, searchAll } from '@Events/helpers/filters'

const events = namespace('events')
const instances = namespace('instances')

@Component({
  components: {
    EventFilters,
    EventsGrid,
    ToggleView,
    EventsCalendarList,
    CheckboxFilters,
  },
})
export default class EventListGridView extends Vue {
  @Ref() readonly toggleView!: Element

  @Prop({ type: String }) title?: string
  @Prop({ type: Boolean, default: false }) isArchive!: boolean
  @Prop({ type: Array, required: true }) events!: Event[]
  @Prop({ type: [Array, Object] }) instances?: {
    [key: string]: Instance[]
  } | null
  @Prop() currentMonth!: string
  @Prop({ type: Boolean }) loading!: boolean
  @Prop({ type: String, default: 'EventsGrid' }) view!: ViewOptions
  @Prop({ type: Array }) contentType?: string[]
  @Prop({ type: Boolean, default: false }) isSeriesPage!: boolean

  @events.State
  public filters!: FilterModel

  @events.Action
  protected getEventAvailability!: (params: {
    event_ref: string
    range: DateRangeInput
  }) => Promise<Event>

  @events.Mutation('SET_ACTIVE_FILTERS')
  public setActiveFilters!: (payload: { key: string; value: any }) => void

  @events.Mutation('RESET_FILTERS')
  protected resetFilters!: () => void

  protected availability: Event[] = []

  @instances.Action
  filterInstances

  @instances.Action
  setInstanceFilters

  public searchTerm: string = ''
  public eventTypeFilters = [
    {
      label: 'Gigs & Events',
      value: 'event',
    },
    {
      label: 'Workshops & Drop Ins',
      value: 'class',
    },
  ]

  @Emit()
  changeView(view: ViewOptions) {
    if (view === this.view) return

    return view
  }

  @Emit()
  handleMonthChange(val) {
    return val
  }

  clearSearchTerm(): void {
    urlParams.reset(this)
    this.searchTerm = ''
  }

  /**
   * See https://app.asana.com/0/1205230281296478/1205867974473415
   */
  get isNarrowLayout() {
    return this.isSeriesPage && this.title === 'KAGAMI'
  }

  get loadAvailability() {
    return this.isNarrowLayout
  }

  get filtersApplied(): boolean {
    let result = false
    for (const fil of Object.keys(this.filters)) {
      if (this.filters[fil] && this.filters[fil].length > 0) {
        result = true
      }
    }

    if (
      typeof this.filters.dateRange.to !== 'undefined' ||
      typeof this.filters.dateRange.from !== 'undefined'
    ) {
      result = true
    }

    if (this.filters.free === true) {
      result = true
    }

    return result
  }

  get filteredEvents() {
    return filterData(this.events, this.filters, this.$moment)
  }

  get showFilters() {
    return (
      this.events &&
      this.events.length > 0 &&
      this.view === 'EventsGrid' &&
      !this.isArchive
    )
  }

  get eventsToShow() {
    return this.filtersApplied
      ? searchAll(this.filteredEvents, this.searchTerm)
      : searchAll(this.events, this.searchTerm)
  }

  get typeFilters() {
    return this.filters.eventTypes
  }

  set typeFilters(val) {
    if (val.length) {
      urlParams.update(this, 'type', val)
    } else {
      urlParams.reset(this)
      this.resetFilters()
    }

    this.setActiveFilters({ key: 'eventTypes', value: val })
    this.setInstanceFilters(val)
    this.filterInstances()
  }

  get noResultsType(): string {
    const map = {
      empty: 'empty-events',
      emptySearched: 'empty-searched-events',
      emptyFiltered: 'empty-filtered-events',
      emptyFilteredSearch: 'empty-filtered-searched-events',
    }

    const onlyFilters = String(this.searchTerm) === '' && this.filtersApplied
    const onlySearch =
      String(this.searchTerm).length > 0 && !this.filtersApplied
    const searchAndFilters =
      String(this.searchTerm).length > 0 && this.filtersApplied

    const key = searchAndFilters
      ? 'emptyFilteredSearch'
      : onlySearch
      ? 'emptySearched'
      : onlyFilters
      ? 'emptyFiltered'
      : 'empty'

    return map[key] || 'empty'
  }

  get availabilityRange(): DateRangeInput {
    const startDate = this.$moment(`${this.currentMonth}-01`).startOf('month')
    const endDate = this.$moment(`${this.currentMonth}-01`)
      .add(1, 'month')
      .startOf('month')
    return {
      from: startDate.format('YYYY-MM-DD HH:mm:ss'),
      to: endDate.format('YYYY-MM-DD HH:mm:ss'),
    }
  }

  @Throttle(500)
  async getSeriesEventAvailability(event_ref) {
    if (this.loadAvailability && event_ref) {
      if (!this.availability?.find((e) => e.event_ref === event_ref)) {
        const availability = await this.getEventAvailability({
          event_ref,
          range: this.availabilityRange,
        })

        this.availability.push(availability)
      }
    }
  }

  mounted() {
    if (this.contentType) {
      this.typeFilters = this.contentType
    }
  }
}

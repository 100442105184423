













































import { Component, Emit, Prop, VModel, Vue } from 'nuxt-property-decorator'
import SearchEvents from '@Events/components/events/search-events/SearchEvents.vue'
import GridIcon from '~/static/images/icons/grid.svg?inline'
import ListIcon from '~/static/images/icons/list.svg?inline'

export type EventsView = 'EventsGrid' | 'EventsCalendarList'

@Component({
  components: {
    SearchEvents,
    GridIcon,
    ListIcon,
  },
})
export default class ToggleView extends Vue {
  @VModel({ type: String }) searchTerm!: string
  @Prop({ type: Boolean, default: false }) isArchive!: boolean
  @Prop({ type: String, default: 'EventsGrid' }) view!: EventsView
  @Prop({ type: Boolean, default: false }) isSeriesPage!: boolean

  @Emit()
  changeView(value: EventsView) {
    return value
  }

  @Emit()
  clearSearchTerm() {
    return 'clearSearchTerm'
  }
}






























































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { singleDateFormatter } from '@Core/helpers/dateFormatter'
import { parseAccessibilityTags } from '@Events/helpers/accessibilityTags'
import { Instance, Event } from '@Core/@types/skyway'
import { formatTime } from '@Account/helpers/format-time'
import ArrowRightSvg from '~/static/images/icons/arrow.svg?inline'

@Component({
  components: {
    ArrowRightSvg,
  },
})
export default class DateCardDetails extends Vue {
  @Prop({ type: Object }) item!: Instance & {
    event: Event
    series_title?: string
  }
  @Prop({ type: String, default: 'hidden' }) className?: string
  @Prop({ type: Boolean, default: false }) isSeriesPage!: boolean

  get date(): string {
    return singleDateFormatter(this.$moment(this.item.date_time))
  }

  get time(): string {
    return formatTime(this.item.date_time, this.$moment)
  }

  get accessibilityItems(): string[] | [] | null {
    if (this.item && this.item.accessibility) {
      return parseAccessibilityTags(this.item.accessibility)
    }
  }

  get link() {
    return this.isSoldOut
      ? this.$route.path
      : `${this.$config.get('URLS').select_area}${this.item.instance_ref}`
  }

  get isSoldOut() {
    return this.item?.total_availability === 0
  }
}
